import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import {
  agencyUserRole,
  userPreference,
  userPreferenceType,
  userStatus,
  userStatusType,
} from '../../../../types/agencyUserTypes'
import { formatAsPhoneNumber } from '../../../../utils/formatAs'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProfileEmployee, sendProfileReminder } from '../../actions/profileActions'
import Loader from '../../../../components/Common/Loader/Loader'
import ProfileAddEmployeeModal from '../ProfileAddEmployeeModal/ProfileAddEmployeeModal'
import './ProfileEmployeeItem.scss'

const ProfileEmployeeItem = ({ employee, isCurrentUser, addNotification, showMenu = true }) => {
  const { t, i18n } = useTranslation('profile')
  const dispatch = useDispatch()
  const { deleteEmployeeIds, deleteEmployeeLoading } = useSelector(state => state.profile)
  const preferences = employee.Preferences
    ? Object.entries(employee.Preferences)
        .filter(([_, v]) => v)
        .map(([k, _]) => k.charAt(0).toLowerCase() + k.slice(1))
        .filter(p => Object.keys(userPreferenceType).includes(p))
    : []

  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const menuItems = [
    {
      text: t('employeesCard.menu.edit'),
      clickHandler: () => setShowEditModal(true),
    },
    {
      text: t('employeesCard.menu.sendReminder'),
      clickHandler: () => dispatch(sendProfileReminder(employee.Id)),
    },
    !deleteEmployeeIds.includes(employee.Id) && {
      text: t('employeesCard.menu.delete'),
      clickHandler: () => setShowRemoveModal(true),
      warn: true,
    },
  ]

  return (
    <>
      <div className='employees-card-grid employees-card-item'>
        {deleteEmployeeIds.includes(employee.Id) && deleteEmployeeLoading && (
          <div className='employees-card-item-loader-overlay'>
            <Loader />
          </div>
        )}
        <div className={classNames('employee-avatar-container', { current: isCurrentUser })}>
          <div className='employee-avatar'>{getUserNameInitials(employee.FullName)}</div>
        </div>
        <div className='employee-name-container'>
          <div className='employee-name'>{`${employee.FullName}`}</div>
          <div className='employee-status'>
            {!employee.IsActive
              ? i18n.language === 'he'
                ? userStatus[userStatusType.inviteWaiting].name
                : userStatus[userStatusType.inviteWaiting].nameEn
              : isCurrentUser && t('employeesCard.you')}
          </div>
        </div>
        <div style={{ textAlign: 'right' }} dir='ltr'>
          {formatAsPhoneNumber(employee.PhoneNumber)}
        </div>
        <div>
          {i18n.language === 'he' ? agencyUserRole[employee.Role]?.name : agencyUserRole[employee.Role]?.nameEn}
        </div>
        <div className='employee-preferences-container'>
          {preferences.length > 0 && (
            <span
              title={preferences
                .map(p => (i18n.language === 'he' ? userPreference[p].name : userPreference[p].nameEn))
                .join(', ')}
            >
              {preferences
                .map(p => (i18n.language === 'he' ? userPreference[p].name : userPreference[p].nameEn))
                .join(', ')}
            </span>
          )}
        </div>
        <div className='employee-menu-container'>
          {showMenu && <MeatBallsMenu menuItems={menuItems} positionFixed />}
        </div>
      </div>
      {showEditModal && <ProfileAddEmployeeModal closeModal={() => setShowEditModal(false)} employee={employee} />}
      {showRemoveModal && (
        <ConfirmDialog
          confirm={() =>
            addNotification({
              text: t('employeesCard.menu.deleteMsg', { userName: employee.FullName }),
              textEn: t('employeesCard.menu.deleteMsg', { userName: employee.FullName }),
              action: () => dispatch(deleteProfileEmployee(employee.Id)),
              actionText: 'ביטול',
              actionTextEn: 'Cancel',
              id: 'deleteUser' + employee.Id,
            })
          }
          decline={() => {
            setShowRemoveModal(false)
          }}
          title={t('employeesCard.menu.deleteModalTitle')}
          text={
            i18n.language === 'he'
              ? `האם אתה בטוח שאתה רוצה להסיר את ${employee.FullName}?`
              : `Are you sure you want to remove ${employee.FullName}?`
          }
          confirmText={t('deleteCompanyModal.modalButton.remove')}
          declineText={t('deleteCompanyModal.modalButton.cancel')}
        />
      )}
    </>
  )
}

export default ProfileEmployeeItem
