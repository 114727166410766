import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import { incomesDataTypes, incomesTotalsObject, incomesTotalsType } from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import AgencyHouseLobbyChanges from '../../../agencyHouseLobby/components/AgencyHouseLobbyChanges/AgencyHouseLobbyChanges'
import AgencyHouseAnalyticsIncomesTotalsCard from './AgencyHouseAnalyticsIncomesTotalsCard'
import { deltaCommissionSplitView } from '../../../shared/reducers/sharedSelectors'
import { isDeltaAgency } from '../../../login/reducers/loginSelectors'
import { useSelector } from 'react-redux'
import { deltaCommissionViewType } from '../../../../types/deltaCommissionViewTypes'
import './AgencyHouseAnalyticsIncomesTotals.scss'

const AgencyHouseAnalyticsIncomesTotals = ({ dataType, periodViewType, totals, totalsLoading }) => {
  const { t } = useTranslation('agencyHouseLobby')
  const { commissionView } = useSelector(({ shared }) => shared)
  const commissionRequestType =
    dataType === requestDataTypes.avarageComission ||
    dataType === requestDataTypes.commission ||
    dataType === requestDataTypes.commissionRate ||
    dataType === requestDataTypes.commissionRateAccumulation ||
    dataType === requestDataTypes.commissionRatePremium

  const incomesDataType = incomesDataTypes.find(dt => dt.id === dataType)
  const totalsValues = totals
    .filter(
      t => incomesDataType.totals.includes(t.type) && incomesTotalsObject[t.type].periodViews.includes(periodViewType)
    )
    .map(t => ({
      ...incomesTotalsObject[t.type],
      typeKey: t.typeKey,
      value: totals.find(tv => tv.type === t.type).value,
      diff: totals.find(tv => tv.type === t.type).diff,
      ...(dataType === requestDataTypes.commission && {
        valueVAT: totals.find(tv => tv.typeKey === 'commissionVAT')?.value,
        diffVAT: totals.find(tv => tv.typeKey === 'commissionVAT')?.diff,
      }),
      ...(incomesDataType.deltaTotals && {
        valueAgency: totals.find(tv => tv.typeKey === t.typeKey + 'Agency')?.value,
        diffAgency: totals.find(tv => tv.typeKey === t.typeKey + 'Agency')?.diff,
        valueBothTotal: totals.find(tv => tv.typeKey === t.typeKey + 'BothTotal')?.value,
        diffBothTotal: totals.find(tv => tv.typeKey === t.typeKey + 'BothTotal')?.diff,
      }),
      ...(incomesDataType.deltaTotals &&
        dataType === requestDataTypes.commission && {
          valueVATAgency: totals.find(tv => tv.typeKey === 'commissionVATAgency')?.value,
          diffVATAgency: totals.find(tv => tv.typeKey === 'commissionVATAgency')?.diff,
          valueVATBothTotal: totals.find(tv => tv.typeKey === 'commissionVATBothTotal')?.value,
          diffVATBothTotal: totals.find(tv => tv.typeKey === 'commissionVATBothTotal')?.diff,
        }),
    }))

  const mainCardKeySuffix = () => {
    if (isDeltaAgency() && commissionRequestType) {
      return commissionView === deltaCommissionViewType.agency ||
        commissionView === deltaCommissionViewType.bothSideBySide
        ? 'Agency'
        : commissionView === deltaCommissionViewType.bothTogether
        ? 'BothTotal'
        : ''
    }
    return ''
  }

  const mainCardNamePrefix = () => {
    if (isDeltaAgency() && commissionRequestType) {
      if (
        commissionView === deltaCommissionViewType.agency ||
        commissionView === deltaCommissionViewType.bothSideBySide
      )
        return (
          <>
            {t('totals.agencyPrefix')}
            <br />
          </>
        )
      if (commissionView === deltaCommissionViewType.agent)
        return (
          <>
            {t('totals.agentPrefix')}
            <br />
          </>
        )
      if (commissionView === deltaCommissionViewType.bothTogether)
        return (
          <>
            {t('totals.agencyAgentPrefix')}
            <br />
          </>
        )
    }
    return null
  }

  const financialDataType = dataType !== requestDataTypes.commissionRate
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly

  const totalName = ({ name, nameEn, nameMonthlyView, nameEnMonthlyView }) => {
    if (monthlyView) {
      return hebrew() ? nameMonthlyView : nameEnMonthlyView
    }
    return hebrew() ? name : nameEn
  }

  const totalValue = (total, keySuffix = '') => {
    const value = total[`value${keySuffix}`]
    return (
      <>
        <h5>
          {value === null || value === undefined ? (
            '--'
          ) : financialDataType ? (
            <>
              <span>₪</span>
              {formatAsCurrency(value < 100000000 ? value : Math.round(value), {
                currency: '',
                fractionDigits: value < 100000000 ? 2 : 0,
              })}
            </>
          ) : (
            formatAsPercent(value, {
              ...(total.type === incomesTotalsType.commissionRateAccumulation && { maximumFractionDigits: 4 }),
            })
          )}
        </h5>
        {dataType === requestDataTypes.commission && total.type === incomesTotalsType.commissionMonthly && (
          <div className='commission-vat'>
            ({t('totals.inclVat')}: {formatAsCurrency(total[`valueVAT${keySuffix}`])})
          </div>
        )}
      </>
    )
  }

  const totalDiff = (total, keySuffix = '') => (
    <AgencyHouseLobbyChanges
      value={total[`diff${keySuffix}`]}
      showValue={
        dataType !== requestDataTypes.accumulation || (dataType === requestDataTypes.accumulation && monthlyView)
      }
      showPercentSymbol={dataType === requestDataTypes.commissionRate}
      showCurrencySymbol={dataType !== requestDataTypes.commissionRate}
      {...(!monthlyView && { changesText: t('totals.prevPeriodChanges') })}
      {...(total.type === incomesTotalsType.commissionRateAccumulation && { maximumFractionDigits: 4 })}
    />
  )

  return (
    <div className='agency-house-analytics-incomes-totals'>
      {totalsValues.map(tv => (
        <AgencyHouseAnalyticsIncomesTotalsCard
          key={tv.name}
          name={
            <>
              {mainCardNamePrefix()}
              {totalName(tv)}
            </>
          }
          icon={tv.icon}
          value={totalValue(tv, mainCardKeySuffix())}
          difference={totalDiff(tv, mainCardKeySuffix())}
          loading={totalsLoading}
          {...(commissionRequestType &&
            deltaCommissionSplitView() && {
              additionalDifference: totalDiff(tv),
              additionalName: t('totals.agentPrefix'),
              additionalValue: totalValue(tv),
            })}
        />
      ))}
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesTotals
