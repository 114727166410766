import $api, { getCancelToken } from '..'
import { urlAgentGroups } from '../../environment/urls'
export const getFilteredAgentNumbersRequest = async ({
  page = 1,
  pageSize = 20,
  sortBy,
  descending = true,
  entityTypes = [],
  fields = [],
  companies = [],
  search = '',
  deleted = false,
  monthAdded,
} = {}) =>
  await $api.post(
    `${urlAgentGroups()}agents`,
    {
      page,
      pageSize,
      sortBy,
      descending,
      entityTypes,
      insuranceFields: fields,
      companies,
      search,
      deleted,
      monthAdded,
    },
    getCancelToken('getAgentNumbersRequest')
  )
