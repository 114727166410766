import { HubConnectionBuilder } from '@microsoft/signalr'
import { useEffect, useState } from 'react'
import { urlSignalRCrawler } from '../../environment/urls'
import { getItemFromLocalStorage } from '../../services/localStorageService'
import './SignalRCrawlerNotifications.scss'

const SignalRCrawlerNotifications = () => {
  const [connection, setConnection] = useState(null)
  const [notifications, setNotifications] = useState([])

  const addNotification = notification => setNotifications(prev => [...prev, { ...notification, shown: false }])

  const accessToken = getItemFromLocalStorage('AccessToken')

  useEffect(() => {
    if (accessToken && !connection) {
      //console.log('connecting crawler signalR')
      const connect = new HubConnectionBuilder()
        .withUrl(`${urlSignalRCrawler()}notficationshub`, {
          accessTokenFactory: () => accessToken,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .withAutomaticReconnect()
        .build()
      setConnection(connect)
    } else if (!accessToken && connection) {
      connection.stop().then(() => setConnection(null))
    } else if (connection && connection.state !== 'Connected') {
      connection
        .start()
        .then(() => {
          connection.on('CustomerCount', notification => {
            addNotification(notification)
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [connection, accessToken])

  const notificationToShow = notifications.find(n => !n.shown)
  if (!notificationToShow) return null
  return null
  // return (
  //   <CustomersLimitWarn
  //     customersCount={notificationToShow.newValue}
  //     closeWarn={() =>
  //       setNotifications(prev => prev.map(n => ({ ...n, shown: n.type === notificationToShow.type ? true : n.shown })))
  //     }
  //   />
  // )
}

export default SignalRCrawlerNotifications
