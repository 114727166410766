import { useTranslation } from 'react-i18next'
import { insuranceField, insuranceFieldType } from '../../../../types/insuranceField'
import { hebrew } from '../../../../i18n'
import { isMobile } from 'react-device-detect'
import './MarketingCompanies.scss'
import { bussinessType, companies, companyConnection } from '../../../../types/companies'
import classNames from 'classnames'
import { getMonthName } from '../../../../services/getMonthName'

const companiesSorter = (a, b) => {
  const aFieldsLength = a.fields.filter(cf => !(cf?.inProgress || cf?.expected)).length
  const bFieldsLength = b.fields.filter(cf => !(cf?.inProgress || cf?.expected)).length
  return bFieldsLength - aFieldsLength
}
const insuranceCompanies = companies.filter(c => c.bussinessType === bussinessType.insurance).sort(companiesSorter)
const investCompanies = companies.filter(c => c.bussinessType === bussinessType.investment).sort(companiesSorter)

const MarketingCompanies = () => {
  const { t, i18n } = useTranslation('common')

  const renderCompaniesList = companies => (
    <ul className='marketing-companies-list'>
      {companies
        .filter(c => !c.hidden)
        .map(c => (
          <li
            key={c.id}
            className={classNames('marketing-company', {
              disabled: !c.fields.filter(cf => !(cf?.inProgress || cf?.expected)).length > 0,
            })}
          >
            <div className='marketing-company-main-container'>
              <div className='marketing-company-logo-container'>
                <div className='marketing-company-logo-icon'>
                  <img src={c.iconLogoPath} alt={c.name} />
                </div>
              </div>
              <div className='marketing-company-info-container'>
                <div className='marketing-company-name'>{hebrew() ? c.name : c.nameEn}</div>
                <div className='marketing-company-fields'>
                  {Object.values(insuranceFieldType).map(f => {
                    const companyField = c.fields && c.fields.find(cf => cf.type === f)
                    const expected = !companyField?.inProgress && companyField?.expected
                    const inProgress = companyField?.inProgress
                    return (
                      <div key={f} className={classNames('marketing-company-field', { disabled: !companyField })}>
                        <div className='marketing-company-field-icon'>
                          <img
                            src={`./assets/${
                              !companyField
                                ? 'close-icon-square/close-square.png'
                                : inProgress
                                ? 'loader-yellow/loader-yellow-sm.png'
                                : expected
                                ? 'calendar-icon/Calendar.png'
                                : 'checkbox-xs-checked/Check Box .png'
                            }`}
                            alt='i'
                          />
                        </div>
                        <div className={classNames('marketing-company-field-info')}>
                          <div className={classNames('marketing-company-field-name')}>
                            {hebrew() ? insuranceField[f].name : insuranceField[f].nameEn}
                          </div>
                          {(inProgress || expected) && (
                            <div className={classNames('marketing-company-field-status', { progress: inProgress })}>
                              {inProgress
                                ? t(`marketingPageCompanies.inProgress`)
                                : t('marketingPageCompanies.expected', {
                                    month: getMonthName(companyField?.expected, i18n.language),
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className='marketing-company-connections'>
              {c.connectionTypes &&
                c.connectionTypes.map(ct => (
                  <div key={ct} className='marketing-company-connection'>
                    <div className='marketing-company-connection-icon'>
                      <img src={companyConnection[ct].iconPath} alt={companyConnection[ct].nameEn} />
                    </div>
                    <p className='marketing-company-connection-name'>
                      {hebrew() ? companyConnection[ct].name : companyConnection[ct].nameEn}
                    </p>
                  </div>
                ))}
            </div>
          </li>
        ))}
    </ul>
  )

  return (
    <div
      className='marketing-companies-wrapper'
      style={{
        backgroundImage: `url('assets/_marketing/_companies/bg${isMobile ? '' : ''}.png')`,
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
      }}
    >
      <section className='marketing-companies-container'>
        <div className='marketing-companies-list-container'>
          <h4 className='marketing-companies-list-header'>{t('marketingPageCompanies.insuranceCompaniesTitle')}</h4>
          {renderCompaniesList(insuranceCompanies)}
        </div>
        <div className='marketing-companies-list-container'>
          <h4 className='marketing-companies-list-header'>{t('marketingPageCompanies.investCompaniesTitle')}</h4>
          {renderCompaniesList(investCompanies)}
        </div>
        <p className='marketing-companies-remark'>{t('marketingPageCompanies.remark')}</p>
      </section>
    </div>
  )
}

export default MarketingCompanies
