import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import { policyStatusType } from '../../../../types/policyStatusType'
import './CustomersPoliciesTotals.scss'

const CustomersPoliciesTotals = ({ totals, loading, showList }) => {
  const { t } = useTranslation('customer')

  if (loading) {
    return (
      <div className='customers-policies-totals loading'>
        <Loader />
      </div>
    )
  }

  return (
    <div className='customers-policies-totals'>
      <section onClick={() => showList(null)}>
        <div>
          <img src={`./assets/policies-totals-icons/allPolicies.png`} alt={'allPolicies'} />
        </div>
        <p>{t(`policiesTotals.allPolicies`)}</p>
        <p>{totals.allPolicies ?? '--'}</p>
      </section>
      <section onClick={() => showList(policyStatusType.notActive)}>
        <div>
          <img src={`./assets/policies-totals-icons/inactivePolicies.png`} alt={'inactivePolicies'} />
        </div>
        <p>{t(`policiesTotals.inactivePolicies`)}</p>
        <p>{totals.inactivePolicies ?? '--'}</p>
      </section>
      <section onClick={() => showList(policyStatusType.irregular)}>
        <div>
          <img src={`./assets/policies-totals-icons/irregularPolicies.png`} alt={'irregularPolicies'} />
        </div>
        <p>{t(`policiesTotals.irregularPolicies`)}</p>
        <p>{totals.irregularPolicies ?? '--'}</p>
      </section>
    </div>
  )
}

export default CustomersPoliciesTotals
