import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import {
  customersClearList,
  customersSetPage,
  customersSetSearchTerm,
  getCustomers,
  getCustomersFilters,
} from '../../features/customers/actions/customersActions'
import CustomersDashboard from '../../features/customers/components/CustomersDashboard/CustomersDashboard'
import CustomersFilterBar from '../../features/customers/components/CustomersFilterBar/CustomersFilterBar'
import CustomersListContainer from '../../features/customers/components/CustomersListContainer/CustomersListContainer'
import useDebounce from '../../hooks/useDebounce'
import { CUSTOMER_UNCHECK_ALL } from '../../types/actionTypes'
import { customersListType } from '../../types/tablesTypes'
import CustomersGroupsListContainer from '../../features/customers/components/CustomersGroupsListContainer/CustomersGroupsListsContainer'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'
import './CustomersScreen.scss'

const CustomersScreen = () => {
  const companiesFilter = useSelector(state => state.customers).companiesFilter
  const categoriesFilter = useSelector(state => state.customers).categoriesFilter
  const companiesAndFilter = useSelector(state => state.customers).companiesAndFilter
  const categoriesAndFilter = useSelector(state => state.customers).categoriesAndFilter
  const companiesNoFilter = useSelector(state => state.customers).companiesNoFilter
  const categoriesNoFilter = useSelector(state => state.customers).categoriesNoFilter
  const advancedFiltersApplied = useSelector(state => state.customers).advancedFiltersApplied
  const groupFilter = useSelector(state => state.customers).groupFilter
  const searchText = useSelector(state => state.customers).searchTerm
  const sort = useSelector(state => state.customers).sort
  const page = useSelector(state => state.customers).page
  const pageSize = useSelector(state => state.customers).pageSize
  const collapsedGroups = useSelector(state => state.customers).collapsedGroups
  const checkedCustomers = useSelector(state => state.policy).checkedCustomers
  const allCustomers = useSelector(state => state.customers).allCustomers
  const { selectedGroupsIds, familyGroupsType } = useSelector(({ shared }) => shared)

  const groupsToHide = Object.entries(collapsedGroups)
    .filter(([key, v]) => !v.show)
    .map(([key]) => key)
  const totalItems = allCustomers?.groups
    ?.filter(gr => !groupsToHide.includes(gr.groupName))
    .map(grp => grp.itemsInGroup)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const history = useHistory()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(history.location.search)
  const type = searchParams.get('type')
  const initListType = Object.values(customersListType).includes(type) ? type : customersListType.total
  const [currentListType, setCurrentListType] = useState(initListType)
  const [resetTotalItems, setResetTotalItems] = useState(true)

  const prevListType = useRef(initListType)
  const initRender1 = useRef(true)
  const initRender2 = useRef(true)
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') ?? '')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const setCurrentListTypeHandler = listType => {
    if (currentListType !== listType) {
      setCurrentListType(listType)
      setResetTotalItems(true)
      if (page !== 1) dispatch(customersSetPage(1))
      searchParams.set('type', listType)
      history.push(`/customers?${searchParams.toString()}`)
    }
  }
  const { t } = useTranslation('common')

  useEffect(() => {
    setCurrentListType(Object.values(customersListType).includes(type) ? type : customersListType.rating)
  }, [type])

  useEffect(() => {
    prevListType.current = currentListType
  }, [currentListType])

  useEffect(() => {
    const text = debouncedSearchTerm.toUpperCase()
    if (text === '') {
      searchParams.delete('search')
    } else {
      searchParams.set('search', text)
    }
    history.replace({ search: searchParams.toString() })
    dispatch(customersSetSearchTerm(text))
  }, [debouncedSearchTerm, dispatch])

  useEffect(() => {
    dispatch(getCustomersFilters())
  }, [dispatch])

  useEffect(() => {
    if (initRender1.current) {
      initRender1.current = false
      return
    }
    if (companiesFilter && categoriesFilter) {
      if (currentListType !== prevListType.current && currentListType !== customersListType.groups) {
        setCurrentListType(currentListType)
        setResetTotalItems(true)
      } else {
        setResetTotalItems(false)
        dispatch(getCustomers({ listType: currentListType, totalElements: resetTotalItems ? 0 : totalItems }))
      }
    }
    return () => {
      dispatch(customersClearList())
    }
  }, [
    dispatch,
    companiesFilter,
    categoriesFilter,
    companiesAndFilter,
    categoriesAndFilter,
    companiesNoFilter,
    categoriesNoFilter,
    advancedFiltersApplied,
    groupFilter,
    searchText,
    sort,
    page,
    pageSize,
    currentListType,
    collapsedGroups,
    familyGroupsType,
  ])

  useEffect(() => {
    if (initRender2.current) {
      initRender2.current = false
      return
    }
    if (page === 1 && currentListType) {
      dispatch(getCustomers({ listType: currentListType, totalElements: resetTotalItems ? 0 : totalItems }))
    } else {
      dispatch(customersSetPage(1))
    }
  }, [selectedGroupsIds, dispatch])

  useEffect(() => {
    checkedCustomers.length > 0 && dispatch({ type: CUSTOMER_UNCHECK_ALL })
    return () => checkedCustomers.length > 0 && dispatch({ type: CUSTOMER_UNCHECK_ALL })
  }, [dispatch, currentListType])

  if (isMobile) {
    return <NoMobileLayout pageName={t('sidebar.customers')} />
  }

  return (
    <div className='customers-screen'>
      <div className='customers-screen-banners'>
        <BackToBigAgency />
        <AgentGroupsBanner />
      </div>
      <div className='customers-main-container'>
        <CustomersDashboard currentListType={currentListType} setCurrentListType={setCurrentListTypeHandler} />
        <CustomersFilterBar currentListType={currentListType} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {currentListType === customersListType.groups ? (
          <CustomersGroupsListContainer />
        ) : (
          <CustomersListContainer currentListType={currentListType} searchTerm={debouncedSearchTerm} />
        )}
      </div>
    </div>
  )
}

export default CustomersScreen
