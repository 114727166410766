import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import i18n from '../../../../i18n'
import { CUSTOMER_UNCHECK_ALL } from '../../../../types/actionTypes'
import { customersListType } from '../../../../types/tablesTypes'
import { policyRating } from '../../../../types/policyRatingTypes'
import { sortBy, sortOrder } from '../../../../types/sortByTypes'
import { checkCustomer } from '../../../policy/actions/policyActions'
import {
  customersCollapseGroup,
  customersSetPage,
  customersSetPageSize,
  customersSetSort,
} from '../../actions/customersActions'
import PoliciesActions from '../../../policy/components/PoliciesActions/PoliciesActions'
import CustomersListItem from './CustomersListItem'
import NoData from '../../../../components/Common/NoData/NoData'
import Loader from '../../../../components/Common/Loader/Loader'
import PaginateIrreg from '../../../../components/Common/PaginateIrreg/PaginateIrreg'
import useDebounce from '../../../../hooks/useDebounce'
import CustomersAppliedFiltersBanner from '../CustomersAppliedFiltersBanner/CustomersAppliedFiltersBanner'
import { cn } from '../../../../utils/stylesUtils'
import {
  commissionHeaderKeySuffix,
  commissionKeySuffix,
  deltaCommissionSplitView,
} from '../../../shared/reducers/sharedSelectors'
import { formatAsCurrency } from '../../../../utils/formatAs'
import './CustomersListContainer.scss'

const CustomersListContainer = ({ currentListType }) => {
  const { t } = useTranslation('customers')
  const { allCustomers, page, sort, collapsedGroups, groupFilter, pageSize } = useSelector(state => state).customers
  const [itemsPerPage, setItemsPerPage] = useState(pageSize)
  const debouncedPageSize = useDebounce(itemsPerPage, 500)
  const groupsToHide = Object.entries(collapsedGroups)
    .filter(([key, v]) => !v.show)
    .map(([key]) => key)
  const totalItems = allCustomers?.groups
    ?.filter(gr => !groupsToHide.includes(gr.groupName))
    .map(grp => grp.itemsInGroup)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const { customersLoading } = useSelector(state => state.customers)
  const checkedCustomers = useSelector(state => state.policy).checkedCustomers

  const dispatch = useDispatch()

  const collapseGroup = key => {
    dispatch(customersCollapseGroup(key))
  }

  const setPageSizeHandler = pageSize => {
    setItemsPerPage(pageSize)
  }

  const setSortHandler = sortBy => {
    dispatch(customersSetSort(sortBy))
  }

  const forwardHandler = () => {
    if (page <= allCustomers.totalPages) {
      dispatch(customersSetPage(page + 1))
    }
  }
  const backwardHandler = () => {
    if (page > 1) {
      dispatch(customersSetPage(page - 1))
    }
  }

  useEffect(() => {
    if (debouncedPageSize !== 0) dispatch(customersSetPageSize(Number(debouncedPageSize)))
  }, [debouncedPageSize, dispatch])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page, pageSize, currentListType])

  return (
    <>
      <div className='customers-list-container'>
        <CustomersAppliedFiltersBanner />
        {customersLoading ? (
          <Loader />
        ) : allCustomers?.groups?.length > 0 && allCustomers?.groups?.some(g => g?.itemsInGroup !== 0) ? (
          <>
            <div className={cn('customers-list-heading', { 'split-commission': deltaCommissionSplitView() })}>
              <div></div>
              <div className='sortable' onClick={() => setSortHandler(sortBy.customerName)}>
                <span>{t('listHeading.customerName')}</span>
                {sort.by === sortBy.customerName && (
                  <i className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                )}
              </div>
              <div className='sortable' onClick={() => setSortHandler(sortBy.customerIdNumber)}>
                <span>{t('listHeading.customerIdNumber')}</span>
                {sort.by === sortBy.customerIdNumber && (
                  <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                )}
              </div>
              {deltaCommissionSplitView() && (
                <div className='sortable' onClick={() => setSortHandler(sortBy.commissionAgency)}>
                  <span>{t('listHeading.commissionAgency')}</span>
                  {sort.by === sortBy.commissionAgency && (
                    <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                  )}
                </div>
              )}
              <div className='sortable' onClick={() => setSortHandler(sortBy[`commission${commissionKeySuffix()}`])}>
                <span>{t(`listHeading.commission${commissionHeaderKeySuffix()}`)}</span>
                {sort.by === sortBy[`commission${commissionKeySuffix()}`] && (
                  <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                )}
              </div>
              <div className='sortable' onClick={() => setSortHandler(sortBy.premium)}>
                <span>{t('listHeading.premium')}</span>
                {sort.by === sortBy.premium && (
                  <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                )}
              </div>
              <div className='sortable' onClick={() => setSortHandler(sortBy.accumulation)}>
                <span>{t('listHeading.accumulation')}</span>
                {sort.by === sortBy.accumulation && (
                  <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                )}
              </div>
              {deltaCommissionSplitView() && (
                <div className='commission-rate'>
                  <div className='commission-rate-heading'>{t('listHeading.commissionRateAgency')}</div>
                  <div className='commission-split'>
                    <div className='sortable' onClick={() => setSortHandler(sortBy.commissionRatePremiumAgency)}>
                      <span>{t('listHeading.premium')}</span>
                      {sort.by === sortBy.commissionRatePremiumAgency && (
                        <i
                          className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}
                        ></i>
                      )}
                    </div>
                    <div className='sortable' onClick={() => setSortHandler(sortBy.commissionRateAccumulationAgency)}>
                      <span>{t('listHeading.accumulation')}</span>
                      {sort.by === sortBy.commissionRateAccumulationAgency && (
                        <i
                          className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className='commission-rate'>
                <div className='commission-rate-heading'>
                  {t(`listHeading.commissionRate${commissionHeaderKeySuffix()}`)}
                </div>
                <div className='commission-split'>
                  <div
                    className='sortable'
                    onClick={() => setSortHandler(sortBy[`commissionRatePremium${commissionKeySuffix()}`])}
                  >
                    <span>{t('listHeading.premium')}</span>
                    {sort.by === sortBy[`commissionRatePremium${commissionKeySuffix()}`] && (
                      <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                    )}
                  </div>
                  <div
                    className='sortable'
                    onClick={() => setSortHandler(sortBy[`commissionRateAccumulation${commissionKeySuffix()}`])}
                  >
                    <span>{t('listHeading.accumulation')}</span>
                    {sort.by === sortBy[`commissionRateAccumulation${commissionKeySuffix()}`] && (
                      <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ justifySelf: 'center' }}>{t('listHeading.status')}</div>
              <div></div>
            </div>
            <div
              className={cn('customers-list-item-totals overall', {
                'split-commission': deltaCommissionSplitView(),
              })}
            >
              <div>{t('listHeading.total')}</div>
              <div></div>
              <div></div>
              <div></div>
              {deltaCommissionSplitView() && (
                <div className='financial-value-cell'>
                  {formatAsCurrency(allCustomers.totalCommissionAgency, { dashesForZero: false })}
                </div>
              )}
              <div className='financial-value-cell'>
                {formatAsCurrency(allCustomers[`totalCommission${commissionKeySuffix()}`], { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsCurrency(allCustomers.totalPremium, { dashesForZero: false })}
              </div>
              <div className='financial-value-cell'>
                {formatAsCurrency(allCustomers.totalAccumulation, { dashesForZero: false })}
              </div>
              {deltaCommissionSplitView() && <div></div>}
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className='customers-list'>
              {allCustomers.groups.map((grp, currGrpIndex, grps) => {
                return (
                  <div key={grp.groupName + currGrpIndex.toString()}>
                    {!(currentListType === customersListType.total || currentListType === customersListType.inactive) &&
                      grp.showGroup && (
                        <>
                          <div className='sublist-heading'>
                            <img
                              src={
                                !groupsToHide.includes(grp.groupName)
                                  ? './assets/minus-icon/Close.png'
                                  : './assets/plus-icon/Add.png'
                              }
                              alt='add'
                              className='img-icon'
                              onClick={() => collapseGroup(grp.groupName)}
                            />
                            <div className='sublist-heading-title' onClick={() => collapseGroup(grp.groupName)}>
                              <span className='title-content'>
                                {i18n.language === 'he'
                                  ? currentListType.includes('rating')
                                    ? policyRating.find(pr => pr.key === +grp.groupName)?.name
                                    : groupFilter.find(gr => gr.id === grp.groupName - 1)?.name
                                  : currentListType.includes('rating')
                                  ? policyRating.find(pr => pr.key === +grp.groupName)?.nameEn
                                  : groupFilter.find(gr => gr.id === grp.groupName - 1)?.nameEn}
                              </span>
                              <span className='items-count'>({grp.itemsInGroup})</span>
                            </div>
                            <hr className=' sublist-heading-line' />
                          </div>
                          <div
                            className={cn('customers-list-item-totals', {
                              'split-commission': deltaCommissionSplitView(),
                            })}
                          >
                            <div>{t('listHeading.total')}</div>
                            <div></div>
                            <div></div>
                            <div></div>
                            {deltaCommissionSplitView() && (
                              <div className='financial-value-cell'>
                                {formatAsCurrency(grp.groupCommissionAgency, { dashesForZero: false })}
                              </div>
                            )}
                            <div className='financial-value-cell'>
                              {formatAsCurrency(grp[`groupCommission${commissionKeySuffix()}`], {
                                dashesForZero: false,
                              })}
                            </div>
                            <div className='financial-value-cell'>
                              {formatAsCurrency(grp.groupPremium, { dashesForZero: false })}
                            </div>
                            <div className='financial-value-cell'>
                              {formatAsCurrency(grp.groupAccumulation, { dashesForZero: false })}
                            </div>
                            {deltaCommissionSplitView() && <div></div>}
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </>
                      )}
                    {grp.showGroup && !groupsToHide.includes(grp.groupName) && grp.groupItems.length === 0 ? (
                      <NoData text='No Data Found' />
                    ) : (
                      grp.groupItems.map(grpItem => (
                        <CustomersListItem
                          item={grpItem}
                          key={grpItem.id}
                          checkCustomer={() => dispatch(checkCustomer(grpItem))}
                          checked={checkedCustomers.map(c => c.id).includes(grpItem.id)}
                        />
                      ))
                    )}
                  </div>
                )
              })}
              {checkedCustomers.length > 0 && (
                <div className='customers-actions-panel-wrapper'>
                  <PoliciesActions
                    customers={checkedCustomers}
                    closeModal={() => dispatch({ type: CUSTOMER_UNCHECK_ALL })}
                  />
                </div>
              )}
            </div>

            <div className='customers-paginate-wrapper'>
              <PaginateIrreg
                page={page - 1}
                pages={allCustomers.totalPages ?? 0}
                pageSize={itemsPerPage}
                totalItems={totalItems}
                forwardHandler={forwardHandler}
                backwardHandler={backwardHandler}
                setPageSize={setPageSizeHandler}
              />
            </div>
          </>
        ) : (
          <NoData text={t('noData')} />
        )}
      </div>
    </>
  )
}

export default CustomersListContainer
