import { useState } from 'react'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import { useTranslation } from 'react-i18next'
import { formatAsShortDate } from '../../../../utils/formatAs'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import ProfileConnectedAgencySettingsModal from '../ProfileConnectedAgencySettingsModal/ProfileConnectedAgencySettingsModal'
import { echo } from '../../../../utils/consoleUtils'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { cancelProfileConnectedAgenciesRequest } from '../../../../http/requests/profileRequests'
import './ProfileConnectedAgency.scss'

const ProfileConnectedAgency = ({ agency, updateAgency }) => {
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [showWarnModal, setShowWarnModal] = useState(false)
  const [connectionLoading, setConnectionLoading] = useState(false)

  const { t } = useTranslation('profile')
  const connectionCancelled = agency.cancelledDate
  const fullAccess = !Object.values(agency.preferences).some(pr => !pr) && !agency.companies.some(c => !c.hasAccess)

  const updateConnection = settings => {}

  const cancelConnection = async () => {
    try {
      setConnectionLoading(true)
      const {
        data: { data, hasError },
      } = await cancelProfileConnectedAgenciesRequest(agency.id)
      if (data && !hasError) {
        updateAgency({
          ...agency,
          cancelledDate: new Date(),
        })
        setShowSettingsModal(false)
      }
      setConnectionLoading(false)
    } catch (error) {
      echo(echo.asAlert('ERROR'))
      echo(echo.asWarning(error))
      setConnectionLoading(false)
    }
  }

  return (
    <>
      <div className='profile-connected-agency'>
        <div>
          <div>
            {/* <div>Logo</div> */}
            <div>
              <p>{agency.name}</p>
              <p>{t(`agencies.${fullAccess ? 'full' : 'partial'}Mirroring`)}</p>
            </div>
          </div>
          <ButtonText styles={'success'} disabled={connectionCancelled} onClick={() => setShowSettingsModal(true)}>
            {t('agencies.settingsBtn')}
          </ButtonText>
        </div>
        <div>
          {connectionCancelled ? (
            <p>
              {t('agencies.cancelledDate')}: {formatAsShortDate(agency.cancelledDate)}
            </p>
          ) : (
            <ButtonRounded
              variant='outlined'
              accent='danger'
              disabled={connectionLoading}
              onClick={() => setShowWarnModal(true)}
            >
              {t('agencies.cancelBtn')}
            </ButtonRounded>
          )}
        </div>
      </div>
      {showSettingsModal && (
        <ProfileConnectedAgencySettingsModal
          closeModal={() => setShowSettingsModal(false)}
          settings={{ ...agency }}
          loading={connectionLoading}
          cancelConnection={() => setShowWarnModal(true)}
          updateConnection={settings => updateConnection(settings)}
        />
      )}
      {showWarnModal && (
        <ConfirmDialog
          renderPortal
          confirm={() => {
            setShowWarnModal(false)
            cancelConnection()
          }}
          decline={() => setShowWarnModal(false)}
          declineText={t('agencies.declineCancel')}
          confirmText={t('agencies.confirmCancel')}
          title={t('agencies.cancelWarnTitle')}
          text={t('agencies.cancelWarnText', { name: agency.name })}
        >
          <div className='cancel-connection-warn-modal-content'>
            <p>{t('agencies.cancelNote')}</p>
            <p>{t('agencies.cancelNoteText')}</p>
          </div>
        </ConfirmDialog>
      )}
    </>
  )
}

export default ProfileConnectedAgency
