import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { rtlDir } from '../../../../i18n'
import { getMonthName, getMonthNameByNumber } from '../../../../services/getMonthName'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import CancelFilledIconSvg from '../../../../components/Common/SvgIcons/CancelFilledIconSvg/CancelFilledIconSvg'
import NoData from '../../../../components/Common/NoData/NoData'
import { REPORTS_DATE_SELECT } from '../../../../types/actionTypesScan'
import './ScanDatepicker.scss'

const ScanDatepicker = () => {
  const { dates: initDates, datesLoading } = useSelector(({ scan }) => scan)
  const [showPicker, setShowPicker] = useState(false)
  const [filtersDirty, setFiltersDirty] = useState(false)
  const [dates, setDates] = useState([])
  const [years, setYears] = useState([])
  const [yearIndex, setYearIndex] = useState(0)
  const [selectedDate, setSelectedDate] = useState(null)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('agencyHouseAnalytics')
  const btnRef = useRef()
  const overlayRef = useRef()

  const hasFiltersApplied = initDates.some(d => d.selected)

  const latestMonthSelected = () =>
    initDates.findLast(d => d.selected).date === initDates.findLast(d => d.hasValue).date

  const getButtonText = () => {
    if (!hasFiltersApplied || datesLoading) {
      return t('datesPicker.btnTitle')
    }
    if (initDates.length === 0) {
      return t('datesPicker.btnTitleNoDates')
    }
    const [year, month] = initDates
      .find(d => d.selected)
      .date.toString()
      .split('-')
    return `${latestMonthSelected() ? t('datesPicker.currentMonth') + ', ' : ''}${getMonthNameByNumber(
      month,
      i18n.language,
      'short'
    )} ${year}`
  }

  const setYearsIndexHandler = (index, yearIndexSetter) => {
    index > -1 && index < years.length && yearIndexSetter(index)
  }

  const selectDate = date => {
    setFiltersDirty(true)
    setSelectedDate(date.toString())
  }

  const getButtonPostition = () => {
    const { bottom, left, right } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom }
  }

  const applyFilters = () => {
    filtersDirty && dispatch({ type: REPORTS_DATE_SELECT, payload: selectedDate })
    setFiltersDirty(false)
    setShowPicker(false)
  }

  const initialize = useCallback(() => {
    const selectedDate = initDates.findLast(d => d.selected).date
    const initYears = []
    initDates.forEach(({ date }) => {
      const year = +date.slice(0, 4)
      !initYears.includes(year) && initYears.push(year)
    })
    const selectedYearIndex = initYears.findLastIndex(y => y === +selectedDate.slice(0, 4)) ?? initYears.length - 1
    setDates(initDates)
    setYears(initYears)
    setSelectedDate(selectedDate)
    setYearIndex(selectedYearIndex)
  }, [initDates])

  useEffect(() => {
    if (showPicker && initDates.length > 0) {
      initialize()
    }
  }, [initDates, showPicker, initialize])

  return (
    <div className='scan-datepicker-container'>
      <div className='scan-datepicker-btn-container' ref={btnRef}>
        <div
          className={classNames('scan-datepicker-btn', { 'has-applied': hasFiltersApplied })}
          onClick={() => setShowPicker(!showPicker)}
        >
          <span>{getButtonText()}</span>
          {hasFiltersApplied && !latestMonthSelected() ? (
            <button
              onClick={e => {
                e.stopPropagation()
                dispatch({ type: REPORTS_DATE_SELECT, payload: initDates.findLast(d => d.hasValue).date })
              }}
            >
              <CancelFilledIconSvg />
            </button>
          ) : showPicker ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )}
        </div>
      </div>
      {showPicker && (
        <div
          className='scan-datepicker-overlay'
          ref={overlayRef}
          onClick={e => {
            if (overlayRef.current === e.target) {
              setShowPicker(false)
              setFiltersDirty(false)
            }
          }}
        >
          <div
            className='scan-datepicker-main-container'
            style={{
              top: getButtonPostition().y + 14 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().left + 'px' }
                : { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }),
            }}
          >
            {initDates.length === 0 ? (
              <div className='scan-datepicker-nodata'>
                <NoData text={t('datesPicker.noDates')} />
              </div>
            ) : (
              <div className='scan-datepicker-calendars-container'>
                <main>
                  <button onClick={() => setShowPicker(false)}>
                    <img src='./assets/close-icon-square/close-square.png' alt='cls' />
                  </button>
                  <div className='scan-datepicker-calendar-container'>
                    <YearsPicker
                      yearIndex={yearIndex}
                      years={years}
                      setYearsIndex={index => setYearsIndexHandler(index, setYearIndex)}
                    />
                    <DatesPicker
                      yearIndex={yearIndex}
                      years={years}
                      dates={dates}
                      selectedDate={selectedDate}
                      selectDate={selectDate}
                      lng={i18n.language}
                    />
                  </div>
                </main>
                <footer>
                  <div>
                    <button onClick={applyFilters}>{t('datesPicker.applyFilterBtn')}</button>
                  </div>
                </footer>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ScanDatepicker

const YearsPicker = ({ years, yearIndex, setYearsIndex }) => {
  return (
    <section className='scan-datepicker-controls'>
      <div>
        <button
          style={{
            visibility: yearIndex > 0 ? 'visible' : 'hidden',
          }}
          onClick={() => setYearsIndex(yearIndex - 1)}
        >
          <img src='./assets/chevron-right/chevron-right.png' alt='prev' />
        </button>
      </div>
      <h3>{years[yearIndex]}</h3>
      <div>
        <button
          style={{
            visibility: yearIndex < years.length - 1 ? 'visible' : 'hidden',
          }}
          onClick={() => setYearsIndex(yearIndex + 1)}
        >
          <img src='./assets/chevron-left/chevron-left.png' alt='next' />
        </button>
      </div>
    </section>
  )
}

const DatesPicker = ({ dates, years, yearIndex, selectedDate, selectDate, lng }) => {
  let calendarItems = dates.filter(d => d.date.includes(years[yearIndex].toString()))

  calendarItems = calendarItems.map(d => ({
    id: d.date,
    selected: selectedDate === d.date,
    nodata: !d.hasValue,
    clickHandler: () => selectDate(d.date),
    text: getMonthName(d.date, lng),
  }))
  return (
    <section className='scan-datepicker'>
      {calendarItems.map(({ id, selected, nodata, text, clickHandler }) => (
        <div
          key={id}
          className={classNames('scan-datepicker-month', {
            selected,
            nodata,
          })}
          {...(!nodata && { onClick: clickHandler })}
        >
          {text}
        </div>
      ))}
    </section>
  )
}
