import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../components/Common/Loader/Loader'
import AgencyHouseLobbySignalCard from '../../../agencyHouseLobby/components/AgencyHouseLobbySignals/AgencyHouseLobbySignalCard'
import {
  getAgencyHouseAnalyticsSignalDetailsRequest,
  getAgencyHouseAnalyticsSignalsRequest,
} from '../../../../http/requests/agencyHouseRequests'
import AgencyHouseAnalyticsHierarchyPicker from '../AgencyHouseAnalyticsHierarchyPicker/AgencyHouseAnalyticsHierarchyPicker'
import { analyticsIncomesList, analyticsIncomesListType } from '../../../../types/analyticsIncomesTypes'
import { hebrew } from '../../../../i18n'
import NoData from '../../../../components/Common/NoData/NoData'
import { getCheckedIds, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import { getRandomHexColor } from '../../../../services/getRandom'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Fragment } from 'react-is'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { categoriesObjects } from '../../../../types/categoriesTypes'
import { irregularSignalType } from '../../../../types/irregularSignals'
import AgencyHouseAnalyticsFiltersPicker from '../AgencyHouseAnalyticsFiltersPicker/AgencyHouseAnalyticsFiltersPicker'
import {
  AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY,
  AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY,
} from '../../../../types/actionTypes'
import './AgencyHouseAnalyticsSignals.scss'

const AgencyHouseAnalyticsSignals = () => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const { selectedAgencies, companies, categories, filtersLoading } = useSelector(
    ({ agencyHouseAnalytics }) => agencyHouseAnalytics
  )
  const [signals, setSignals] = useState({})
  const [signalsLoading, setSignalsLoading] = useState(true)
  const [selectedSignal, setSelectedSignal] = useState(null)
  const [nestedEntityType, setNestedEntityType] = useState(analyticsIncomesListType.companies)
  const [signalDetails, setSignalDetails] = useState([])
  const [signalDetailsLoading, setSignalDetailsLoading] = useState(false)
  const [openAgencies, setOpenAgencies] = useState([])

  const hierarchy = [
    [analyticsIncomesListType.agents, analyticsIncomesListType.companies],
    [analyticsIncomesListType.agents, analyticsIncomesListType.categories],
  ]

  const getHierarcies = () =>
    hierarchy.map(h => ({
      id: h[0] + '' + h[1],
      text:
        t('orderBy') +
        (hebrew()
          ? analyticsIncomesList[h[0]].name + ' -> ' + analyticsIncomesList[h[1]].name
          : analyticsIncomesList[h[0]].nameEn + ' -> ' + analyticsIncomesList[h[1]].nameEn),
      value: h[1],
      selected: h[1] === nestedEntityType,
    }))

  const getSubItems = agency => {
    return nestedEntityType === analyticsIncomesListType.companies ? agency.companies : agency.categories
  }

  const getSelectedAgencies = useCallback(
    () => selectedAgencies.filter(a => a.selected).map(a => a.id),
    [selectedAgencies]
  )

  const dispatch = useDispatch()
  const applyFiltersHandler = actionType => ids => {
    dispatch({
      type: actionType,
      payload: ids,
    })
  }

  useEffect(() => {
    const fetchSignals = async () => {
      try {
        setSignalsLoading(true)
        setSignals({})
        setSignalDetails([])
        setSelectedSignal(null)
        const {
          data: { Data },
        } = await getAgencyHouseAnalyticsSignalsRequest({
          agenciesIds: getSelectedAgencies(),
          companies: getCheckedIds(companies, 'selected'),
          categories: getCheckedIds(categories, 'selected'),
        })
        setSignals({
          ...(Data.MissingOrNoPremiumIrregularitiesCount && {
            cancellation: {
              count: Data.MissingOrNoPremiumIrregularitiesCount,
              value: Data.MissingOrNoPremiumIrregularitiesValue,
              type: irregularSignalType.cancellation,
            },
          }),
          ...(Data.NegativePaymentIrregularitiesCount && {
            negativePayment: {
              count: Data.NegativePaymentIrregularitiesCount,
              value: Data.NegativePaymentIrregularitiesValue,
              type: irregularSignalType.negativePayment,
            },
          }),
          ...(Data.CommissionRateIrregularitiesCount && {
            commissionDowngrade: {
              count: Data.CommissionRateIrregularitiesCount,
              value: Data.CommissionRateIrregularitiesValue,
              type: irregularSignalType.commissionDowngrade,
            },
          }),
          ...(Data.PoliciesThatEndsNextMonthCount && {
            renewal: {
              count: Data.PoliciesThatEndsNextMonthCount,
              value: Data.PoliciesThatEndsNextMonthValue,
              type: irregularSignalType.renewal,
            },
          }),
          ...(Data.CommissionZeroIrregularitiesCount && {
            commissionZero: {
              count: Data.CommissionZeroIrregularitiesCount,
              value: Data.CommissionZeroIrregularitiesValue,
              type: irregularSignalType.commissionZero,
            },
          }),
          ...(Data.AccumulationPremiumZeroIrregularitiesCount && {
            accumulationPremiumZero: {
              count: Data.AccumulationPremiumZeroIrregularitiesCount,
              value: Data.AccumulationPremiumZeroIrregularitiesValue,
              type: irregularSignalType.accumulationPremiumZero,
            },
          }),
        })
        setSignalsLoading(false)
      } catch (error) {
        setSignalsLoading(false)
      }
    }

    if (selectedAgencies.length > 0 && companies.length > 0 && categories.length > 0) fetchSignals()
  }, [selectedAgencies, companies, categories, getSelectedAgencies])

  useEffect(() => {
    const fetchSignalDetails = async () => {
      try {
        setSignalDetailsLoading(true)
        setSignalDetails([])
        const {
          data: { Data },
        } = await getAgencyHouseAnalyticsSignalDetailsRequest({
          agenciesIds: getSelectedAgencies(),
          signalType: selectedSignal,
          companies: getCheckedIds(companies, 'selected'),
          categories: getCheckedIds(categories, 'selected'),
        })
        setSignalDetails(lowercaseObjectKeys(Data).map(a => ({ ...a, color: getRandomHexColor() })))
        setSignalDetailsLoading(false)
      } catch (error) {
        setSignalDetailsLoading(false)
      }
    }

    if (
      selectedAgencies.length > 0 &&
      companies.length > 0 &&
      categories.length > 0 &&
      Object.values(signals)
        .map(({ type }) => type)
        .includes(selectedSignal) &&
      !signalsLoading
    )
      fetchSignalDetails()
  }, [selectedAgencies, selectedSignal])

  return (
    <div className='agency-house-analytics-signals'>
      <header>
        <p>{t('signalsHeading')}</p>
        <AgencyHouseAnalyticsFiltersPicker
          filters={selectedAgencies}
          filtersLoading={filtersLoading}
          applyFilters={applyFiltersHandler(AGENT_HOUSE_ANALYTICS_AGENCIES_FILTER_APPLY)}
          filtersTitle={t('filtersPicker.agencies')}
          noFiltersText={t('filtersPicker.noAgencies')}
          noFoundText={t('filtersPicker.noAgenciesFound')}
          searchPlaceholder={t('filtersPicker.searchPlaceholder')}
        />
        <AgencyHouseAnalyticsFiltersPicker
          filters={categories}
          filtersLoading={filtersLoading}
          applyFilters={applyFiltersHandler(AGENT_HOUSE_ANALYTICS_CATEGORIES_FILTER_APPLY)}
          filtersTitle={t('filtersPicker.categories')}
          noFiltersText={t('filtersPicker.noCategories')}
          noFoundText={t('filtersPicker.noCategoriesFound')}
          searchPlaceholder={t('filtersPicker.searchPlaceholder')}
        />
        <AgencyHouseAnalyticsFiltersPicker
          filters={companies}
          filtersLoading={filtersLoading}
          applyFilters={applyFiltersHandler(AGENT_HOUSE_ANALYTICS_COMPANIES_FILTER_APPLY)}
          filtersTitle={t('filtersPicker.companies')}
          noFiltersText={t('filtersPicker.noCompanies')}
          noFoundText={t('filtersPicker.noCompaniesFound')}
          searchPlaceholder={t('filtersPicker.searchPlaceholder')}
        />
      </header>
      <section>
        {signalsLoading ? (
          <Loader />
        ) : Object.entries(signals).length === 0 ? (
          <div className='no-signals-data'>{t('noSignals')}</div>
        ) : (
          Object.entries(signals).map(([k, s]) => (
            <AgencyHouseLobbySignalCard
              key={k}
              signalKey={k}
              count={s.count}
              value={s.value}
              onCardClick={() => setSelectedSignal(s.type)}
              selected={selectedSignal === s.type}
            />
          ))
        )}
      </section>

      <section className='signal-details'>
        {signalDetailsLoading && <Loader />}
        {!signalDetails && !signalDetailsLoading && <NoData text={t('selectSignal')} />}
        {signalDetails.length > 0 && !signalDetailsLoading && (
          <>
            <div className='signal-details-hierarchy-picker'>
              <AgencyHouseAnalyticsHierarchyPicker hierarchies={getHierarcies()} setHierarchy={setNestedEntityType} />
            </div>
            <div className='signal-details-list-container'>
              <div className='signal-details-list-item-grid signal-details-list-header'>
                <div></div>
                <div>{t('tableHeading.agents')}</div>
                <div>{t('tableHeading.policiesAmount')}</div>
                <div>{t('tableHeading.totalPotentialValue')}</div>
              </div>
              <div className='signal-details-list'>
                {signalDetails.map(a => (
                  <Fragment key={a.agencyId}>
                    <div className='signal-details-list-item-grid signal-details-list-item'>
                      <div className='signal-details-item-details-btn'>
                        <button
                          onClick={() =>
                            setOpenAgencies(prev =>
                              prev.includes(a.agencyId) ? prev.filter(id => id !== a.agencyId) : [...prev, a.agencyId]
                            )
                          }
                        >
                          {openAgencies.includes(a.id) ? <ArrowDropUp /> : <ArrowDropDown />}
                        </button>
                      </div>
                      <div className='signal-details-item-name'>
                        <div className='signal-details-item-logo' style={{ background: a.color }}>
                          {getUserNameInitials(a.agencyName)}
                        </div>
                        <p>{a.agencyName}</p>
                      </div>
                      <div>{a.policiesCount ?? '--'}</div>
                      <div style={{ direction: 'ltr', ...(hebrew() && { textAlign: 'end' }) }}>
                        {a.commission !== null ? (
                          <>
                            {a.commission < 0 && <span>{'-'}</span>}
                            {<span className='agency-house-lobby-changes-value-currency'>₪</span>}
                            <span>
                              {new Intl.NumberFormat('en-US', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              }).format(Math.abs(a.commission))}
                            </span>
                          </>
                        ) : (
                          '--'
                        )}
                      </div>
                    </div>
                    {openAgencies.includes(a.agencyId) && (
                      <div className='signal-details-sublist-container'>
                        <div className='signal-details-list-item-subgrid signal-details-list-header'>
                          <div>{t('tableHeading.agents')}</div>
                          <div>{t('tableHeading.policiesAmount')}</div>
                          <div>{t('tableHeading.totalPotentialValue')}</div>
                        </div>
                        <div className='signal-details-sublist'>
                          {getSubItems(a).map(itm => (
                            <div
                              className='signal-details-list-item-subgrid signal-details-list-item'
                              key={itm.id + 'sublist'}
                            >
                              <div className='signal-details-item-name'>
                                <div className='signal-details-subitem-logo'>
                                  {nestedEntityType === analyticsIncomesListType.companies ? (
                                    <img src={`./assets/companies-logos-light/${itm.id}.png`} alt={itm.name} />
                                  ) : (
                                    <div
                                      className='signal-details-subitem-category-badge'
                                      style={{
                                        background:
                                          categoriesObjects[itm.id]?.markerColor ??
                                          categoriesObjects['default'].markerColor,
                                      }}
                                    ></div>
                                  )}
                                </div>
                                <p>{hebrew() ? itm.name : itm.nameEn}</p>
                              </div>
                              <div>{itm.policiesCount ?? '--'}</div>
                              <div style={{ direction: 'ltr', ...(hebrew() && { textAlign: 'end' }) }}>
                                {itm.commission !== null ? (
                                  <>
                                    {itm.commission < 0 && <span>{'-'}</span>}
                                    {<span className='agency-house-lobby-changes-value-currency'>₪</span>}
                                    <span>
                                      {new Intl.NumberFormat('en-US', {
                                        style: 'decimal',
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                      }).format(Math.abs(itm.commission))}
                                    </span>
                                  </>
                                ) : (
                                  '--'
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
          </>
        )}
      </section>
    </div>
  )
}

export default AgencyHouseAnalyticsSignals
