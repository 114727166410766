import { useTranslation } from 'react-i18next'
import { createPortal } from 'react-dom'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import './AgentGroupsSidebar.scss'
import AddAgentGroupModal from '../AddAgentGroupModal/AddAgentGroupModal'
import NoData from '../Common/NoData/NoData'
import { getAgentGroups, removeAgentGroup, selectAgentsGroups } from '../../features/shared/actions/sharedActions'
import Loader from '../Common/Loader/Loader'
import { getUserNameInitials } from '../../services/getUserNameInitials'
import MeatBallsMenu from '../Common/MeatBallsMenu/MeatBallsMenu'
import { getRandomInt } from '../../services/getRandom'
import { avatarStyles } from '../../types/colorsTypes'
import { useCrossDomainCookies } from '../../environment/urls'
import useOutsideClick from '../../hooks/useOutsideClick'
import { OpenInNew } from '@mui/icons-material'

const AgentGroupsSidebar = ({ showSidebar = false, closeSidebar }) => {
  const { agentGroups, agentGroupsLoading, selectedGroupsIds } = useSelector(({ shared }) => shared)
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [updateGroup, setUpdateGroup] = useState(null)
  const [searchGroup, setSearchGroup] = useState('')
  const [idsToSelect, setIdsToSelect] = useState([])
  const [sidebarDirty, setSidebarDirty] = useState(false)
  const dispatch = useDispatch()

  const groups = agentGroups.filter(g => g.name.toLowerCase().includes(searchGroup.toLowerCase()))
  const isSelected = id => idsToSelect.includes(id)
  const selectGroupHandler = id => {
    setSidebarDirty(true)
    setIdsToSelect(prev => (prev.includes(id) ? prev.filter(prevId => prevId !== id) : [...prev, id]))
  }

  const { t } = useTranslation('common')
  const bodyOverflowRef = useRef()

  const groupMenu = group => [
    {
      text: (
        <div className='sidebar-agent-group-menu-item'>
          <div>{t('agentGroups.agentGroupEdit')}</div>
          <div className='sidebar-agent-group-menu-item-ico'>
            <img src='./assets/edit-icon/1@2x.png' alt='edt' style={{ height: '16px' }} />
          </div>
        </div>
      ),
      clickHandler: () => {
        setUpdateGroup(group)
        setShowGroupModal(true)
      },
    },
    {
      text: (
        <div className='sidebar-agent-group-menu-item'>
          <div>{t('agentGroups.agentGroupDelete')}</div>
          <div className='sidebar-agent-group-menu-item-ico'>
            <img src='./assets/delete-icon/Delete.png' alt='dlt' />
          </div>
        </div>
      ),
      clickHandler: () => dispatch(removeAgentGroup(group.id)),
    },
  ]

  const closeSideabrHandler = () => {
    if (sidebarDirty) {
      document.cookie = `agentGroups=${idsToSelect.join('-')}; ${useCrossDomainCookies} ${
        selectedGroupsIds.length === 0 ? 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;' : ''
      }`
      dispatch(selectAgentsGroups(idsToSelect))
      setSidebarDirty(false)
    }
    closeSidebar()
  }

  useEffect(() => {
    if (showSidebar) {
      bodyOverflowRef.current = document.body.style.overflow
      document.body.style.overflow = 'hidden'
      dispatch(getAgentGroups())
      return () => {
        setSearchGroup('')
        document.body.style.overflow = bodyOverflowRef.current
      }
    }
  }, [showSidebar, dispatch])

  useEffect(() => {
    if (showSidebar) {
      setIdsToSelect(selectedGroupsIds)
    }
  }, [showSidebar, selectedGroupsIds, dispatch])

  const sidebarRef = useRef()
  useOutsideClick(sidebarRef, () => showSidebar && !showGroupModal && closeSideabrHandler())

  return createPortal(
    <div className={classNames('agent-groups-sidebar-container', { closed: !showSidebar })}>
      <div className={classNames('agent-groups-sidebar', { closed: !showSidebar })} ref={sidebarRef}>
        <header className='agent-groups-sidebar-header'>
          <div className='agent-groups-close-btn-container'>
            <button onClick={closeSideabrHandler}>
              <img src='./assets/close-icon/Close.png' alt='cls' />
            </button>
          </div>
          <div className='agent-groups-sidebar-header-controls'>
            <div className='agent-groups-sidebar-header-input-container'>
              <div className='agent-groups-sidebar-header-input-ico'>
                <img src='./assets/loupe-icon/loupe.png' alt='srch' />
              </div>
              <input
                placeholder={t('agentGroups.searchPlacehoder')}
                onChange={e => setSearchGroup(e.target.value)}
                value={searchGroup}
              />
              {searchGroup.length > 0 && (
                <div className='agent-groups-sidebar-header-input-clear-btn'>
                  <button onClick={() => setSearchGroup('')}>
                    <img src='./assets/close-icon-sm/Close.png' alt='cls' />
                  </button>
                </div>
              )}
            </div>
            <div className='agent-groups-sidebar-header-btn-container'>
              <button onClick={() => setIdsToSelect([])} className='clear-selected-btn'>
                <img src='./assets/cross-icon/cross.png' alt='clr' />
              </button>
            </div>
            <div className='agent-groups-sidebar-header-btn-container'>
              <button onClick={() => setShowGroupModal(true)}>
                <img src='./assets/icon-add-white/Add.png' alt='add' />
              </button>
            </div>
          </div>
        </header>
        <div className='agent-groups-sidebar-content'>
          {agentGroupsLoading ? (
            <Loader />
          ) : agentGroups.length === 0 ? (
            <div className='agent-groups-sidebar-no-groups'>
              <p>{t('agentGroups.noGroupsFound')}</p>
              <div>
                <button onClick={() => setShowGroupModal(true)}>
                  <div className='agent-groups-sidebar-no-groups-btn-icon'>
                    <img src='./assets/icon-add-white/Add.png' alt='add' />
                  </div>
                  <div className='agent-groups-sidebar-no-groups-btn-text'>{t('agentGroups.addGroupBtn')}</div>
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className='agent-groups-sidebar-list-header'>{t('agentGroups.listHeader')}</div>
              {groups.length === 0 ? (
                <NoData text='No Groups Found' />
              ) : (
                <div className='agent-groups-sidebar-list-wrapper'>
                  <div className='agent-groups-sidebar-list'>
                    {/* <div className='agent-groups-sidebar-list-extras'>
                      <p>Agent Groups</p>
                      <div>
                        <button>
                          <OpenInNew />
                        </button>
                      </div>
                    </div> */}
                    {groups.map(gr => (
                      <div
                        key={gr.id}
                        className={classNames('agent-groups-sidebar-list-item', { selected: isSelected(gr.id) })}
                      >
                        {gr.loading && (
                          <div className='agent-groups-sidebar-list-item-loader-overlay'>
                            <Loader />
                          </div>
                        )}
                        <div className='agent-groups-sidebar-list-item-selector'>
                          <button onClick={() => selectGroupHandler(gr.id)}>
                            <img
                              src={`./assets/checkbox-sq/${isSelected(gr.id) ? 'A' : 'Ina'}ctive.png`}
                              alt={isSelected(gr.id) ? 'unchk' : 'chk'}
                            />
                          </button>
                        </div>
                        <div className='agent-groups-sidebar-list-item-info'>
                          <div
                            className='agent-groups-sidebar-list-item-ava'
                            style={avatarStyles[gr?.colorIndex ?? getRandomInt(1, 3) - 1]}
                          >
                            {getUserNameInitials(gr.name)}
                          </div>
                          <div className='agent-groups-sidebar-list-item-name' title={gr.name}>
                            {gr.name}
                          </div>
                        </div>
                        <div className='agent-groups-sidebar-list-item-menu'>
                          <MeatBallsMenu menuSize='sm' menuItems={groupMenu(gr)} positionFixed />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showGroupModal && (
        <AddAgentGroupModal
          closeModal={() => {
            setShowGroupModal(false)
            updateGroup && setUpdateGroup(null)
          }}
          group={updateGroup}
        />
      )}
    </div>,
    document.body
  )
}

export default AgentGroupsSidebar
