import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import NotifyDialog from '../../../../components/NotifyDialog/NotifyDialog'
import { agencyUserRoleType } from '../../../../types/agencyUserTypes'
import { getProfileEmployees } from '../../actions/profileActions'
import ProfileAddEmployeeModal from '../ProfileAddEmployeeModal/ProfileAddEmployeeModal'
import ProfileEmployeeItem from '../ProfileEmployeeItem/ProfileEmployeeItem'
import './ProfileEmployees.scss'

const ProfileEmployees = () => {
  const { t, i18n } = useTranslation('profile')
  const dispatch = useDispatch()
  const { UserId: currentUserId, role } = useSelector(state => state.login)
  const { employees, employeesLoading } = useSelector(state => state.profile)
  const [notifications, setNotifications] = useState([])
  const [showInviteModal, setShowInviteModal] = useState(false)
  const fullAccessUser = role === agencyUserRoleType.fullUserAccess

  const addNotification = notification => {
    setNotifications(prev => {
      let hasNotification = prev.map(n => n.id).includes(notification.id)
      if (hasNotification) {
        return prev.map(n => (n.id === notification.id ? notification : n))
      } else prev.push(notification)
      return [...prev]
    })
  }

  const removeNotification = id => {
    setNotifications(notifications.filter(n => n.id !== id))
  }

  const isCurrentUser = id => id === currentUserId

  const cardContent = (employeesLoading && <Loader />) ||
    (employees.length === 0 && (
      <div className='employees-card-no-data-container-wrapper'>
        <NoData text='No Employees Found' />
      </div>
    )) || (
      <>
        <div className='employees-card-grid employees-card-header'>
          <div></div>
          <div>{t('employeesCard.tableHeaders.fullName')}</div>
          <div>{t('employeesCard.tableHeaders.phone')}</div>
          <div>{t('employeesCard.tableHeaders.userType')}</div>
          <div>{t('employeesCard.tableHeaders.preferences')}</div>
          <div></div>
        </div>
        <div className='employees-card-items-container'>
          {employees.map(emp => (
            <ProfileEmployeeItem
              employee={emp}
              isCurrentUser={isCurrentUser(emp.Id)}
              key={emp.Id}
              addNotification={addNotification}
              showMenu={!isCurrentUser(emp.Id) && fullAccessUser}
            />
          ))}
        </div>
      </>
    )

  useEffect(() => {
    dispatch(getProfileEmployees())
  }, [dispatch])

  return (
    <>
      <div className='employees-container'>
        <div className='employees-header'>
          <div className='employees-header-title'>{`${t('employeesCardTitle')}${
            employees.length > 0 ? ` (${employees.length})` : ''
          }`}</div>
          {fullAccessUser && (
            <div className='employees-header-action' onClick={() => setShowInviteModal(true)}>
              <div className='employees-header-action-icon'>
                <img src='./assets/icon-add-green/Add.png' alt='invite' />
              </div>
              <div className='employees-header-action-text'>{t('employeeInvite')}</div>
            </div>
          )}
        </div>
        <div className='employees-card-container'>{cardContent}</div>
      </div>
      {showInviteModal && <ProfileAddEmployeeModal closeModal={() => setShowInviteModal(false)} />}
      {notifications.length > 0 && (
        <div className='notify-modals-wrapper'>
          <div className='notify-modals-container'>
            {notifications.map(notify => (
              <NotifyDialog
                text={i18n.language === 'he' ? notify.text : notify.textEn}
                closeNotify={() => removeNotification(notify.id)}
                closeNotifyTimout={3500}
                timeoutStep={500}
                key={notify.id + notify.text}
                confirm={() => notify.cancelAction && notify.cancelAction()}
                onCloseAction={() => notify.action()}
                confirmText={i18n.language === 'he' ? notify.actionText : notify.actionTextEn}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileEmployees
