import AnnualDistributionChart from '../../../../components/Charts/AnnualDistributionChart'
import './AgencyHouseAnalyticsRatingChart.scss'

const AgencyHouseAnalyticsRatingChart = ({ series, labels, colors }) => {
  return (
    <div className='agency-house-analytics-rating-chart'>
      <AnnualDistributionChart
        width={'260'}
        donutSize='70%'
        series={series}
        labels={labels}
        valueFormatter={val => val + '%'}
        colors={colors}
      />
    </div>
  )
}

export default AgencyHouseAnalyticsRatingChart
