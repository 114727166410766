import classNames from 'classnames'
import './ScanCompanyReport.scss'
import { reportRecordStatus, reportRecordStatusType } from '../../../../types/reportRecordStatus'
import {
  CheckRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material'
import InputSwitch from '../../../../components/Common/InputSwitch/InputSwitch'
import { hebrew } from '../../../../i18n'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import MicrosoftExcelIconSvg from '../../../../components/Common/SvgIcons/MicrosoftExcelIconSvg/MicrosoftExcelIconSvg'
import ScanCompanyReportError from '../ScanCompanyReportError/ScanCompanyReportError'
import { useTranslation } from 'react-i18next'
import { downloadReportRequest } from '../../../../http/requests'
import Loader from '../../../../components/Common/Loader/Loader'

const ScanCompanyReport = ({
  report,
  company,
  showErrorReport,
  setShowErrorReport,
  scanReport,
  scanInProgress,
  toggleDisable,
  reoprtLoading,
}) => {
  const { t } = useTranslation('scan')

  const reportSaved = status => status === reportRecordStatusType.saved
  const reportError = status =>
    status !== reportRecordStatusType.saved && status !== reportRecordStatusType.none && !scanInProgress

  const downloadFileHandler = async reportRecordId => {
    await downloadReportRequest({ reportRecordId })
  }

  return (
    <li
      key={report.id}
      className={classNames('scan-company-report', {
        'saved-report': reportSaved(report.status),
        'error-report': reportError(report.status),
        'disabled-report': report.isDisabled || report.isExpired,
        'scan-in-progress': scanInProgress,
        current: report.current,
      })}
    >
      <div className='scan-company-report-summary'>
        {reportSaved(report.status) ? (
          <CheckRounded />
        ) : (
          <InputSwitch
            checked={!report.isDisabled}
            value={report.isDisabled}
            onChange={toggleDisable}
            disabled={scanInProgress || reoprtLoading}
          />
        )}
        <p className='scan-company-report-name'>{report.reportName}</p>
        {!report.isDisabled && reportError(report.status) && (
          <p className='scan-company-report-desc'>
            {hebrew() ? reportRecordStatus[report.status]?.title : reportRecordStatus[report.status]?.titleEn}
          </p>
        )}
        {report.isDisabled && <p className='scan-company-report-desc'>{t('downloadDisabled')}</p>}

        <div className='scan-company-report-actions'>
          {!report.isDisabled && !report.isExpired && !scanInProgress && (
            <>
              {report.filePath && report.filePath.length > 0 && (
                <ButtonRounded
                  onClick={() => {
                    downloadFileHandler(report.id)
                  }}
                >
                  <span>{t('dwnldFileBtn')}</span>
                  <MicrosoftExcelIconSvg />
                </ButtonRounded>
              )}
              {reportError(report.status) && (
                <>
                  <ButtonRounded onClick={scanReport}>{t('tryAgainBtn')}</ButtonRounded>
                  <button
                    onClick={() =>
                      setShowErrorReport(prev =>
                        prev.includes(report.id) ? [...prev.filter(p => p !== report.id)] : [...prev, report.id]
                      )
                    }
                  >
                    {showErrorReport.includes(report.id) ? (
                      <KeyboardArrowDownRounded />
                    ) : hebrew() ? (
                      <KeyboardArrowLeftRounded />
                    ) : (
                      <KeyboardArrowRightRounded />
                    )}
                  </button>
                </>
              )}
            </>
          )}
          {report.current && (
            <>
              <p>{t('downloadInProgress')}</p>
              <Loader color={'var(--trend-up-color)'} />
            </>
          )}
        </div>
      </div>
      {showErrorReport.includes(report.id) && !report.isDisabled && (
        <ScanCompanyReportError report={report} company={company} />
      )}
    </li>
  )
}

export default ScanCompanyReport
