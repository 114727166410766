import { useTranslation } from 'react-i18next'
import {
  analyticsIncomesListType,
  customersTotalsObject,
  customersTotalsType,
  getCustomersTotalsTypes,
} from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import './AgencyHouseAnalyticsCustomersTable.scss'
import { hebrew } from '../../../../i18n'
import Loader from '../../../../components/Common/Loader/Loader'
import { formatAsPercent } from '../../../../utils/formatAs'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { getRandomHexColor } from '../../../../services/getRandom'
import { categoriesObjects } from '../../../../types/categoriesTypes'
import classNames from 'classnames'
import AgencyHouseAnalyticsIncomesNoData from '../AgencyHouseAnalyticsIncomesNoData/AgencyHouseAnalyticsIncomesNoData'

const AgencyHouseAnalyticsCustomersTable = ({
  listType,
  periodViewType,
  list,
  listLoading,
  selectItem,
  selectedItem,
}) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly

  const tableHeader = (
    <header className='agency-house-analytics-customers-table-grid'>
      <div></div>
      <div>
        {t(`tableHeading.${Object.entries(analyticsIncomesListType).find(([key, val]) => val === listType)[0]}`)}
      </div>
      {getCustomersTotalsTypes().map(t => {
        const { name, nameEn, nameMonthlyView, nameEnMonthlyView, type } = customersTotalsObject[t]
        const text = monthlyView ? (hebrew() ? nameMonthlyView : nameEnMonthlyView) : hebrew() ? name : nameEn
        return <div key={type}>{text}</div>
      })}
      <div>{t(`tableHeading.totalPercentage`)}</div>
    </header>
  )

  if (listLoading) {
    return <Loader />
  }
  if (list.length === 0) {
    return <AgencyHouseAnalyticsIncomesNoData />
  }
  return (
    <div className='agency-house-analytics-customers-table-container'>
      {tableHeader}
      <div className='agency-house-analytics-customers-table'>
        {list.map(itm => (
          <AgencyHouseAnalyticsCustomersTableItem
            key={itm.key}
            item={itm}
            listType={listType}
            selectItem={selectItem}
            selectedItem={selectedItem}
          />
        ))}
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsCustomersTable

const AgencyHouseAnalyticsCustomersTableItem = ({ item, listType, selectItem, selectedItem }) => {
  const dataKeys = getCustomersTotalsTypes().map(
    tt => Object.entries(customersTotalsType).find(([key, val]) => val === tt)[0]
  )
  const itemName = hebrew() ? item.name : item.nameEn
  let logo
  switch (listType) {
    case analyticsIncomesListType.agents:
      logo = (
        <div className='agency-house-analytics-customers-table-item-logo' style={{ background: getRandomHexColor() }}>
          {getUserNameInitials(itemName)}
        </div>
      )
      break
    case analyticsIncomesListType.companies:
      logo = (
        <div className='agency-house-analytics-customers-table-item-logo'>
          <img src={`./assets/companies-logos-light/${item.key}.png`} alt={itemName} />
        </div>
      )
      break
    case analyticsIncomesListType.categories:
      logo = (
        <div
          className='agency-house-analytics-customers-table-item-logo small'
          style={{ background: categoriesObjects[item.key]?.markerColor ?? categoriesObjects['default'].markerColor }}
        ></div>
      )
      break
    default:
      break
  }

  return (
    <div
      className={classNames('agency-house-analytics-customers-table-item agency-house-analytics-customers-table-grid', {
        selected: selectedItem && item.key === selectedItem.key,
      })}
      onClick={() => selectItem({ key: item.key, type: listType })}
    >
      <div>{logo}</div>
      <div>{itemName}</div>
      {dataKeys.map(k => (
        <div key={k}>{item[k] ?? '--'}</div>
      ))}

      <div>{formatAsPercent(item.totalPercentage)}</div>
    </div>
  )
}
