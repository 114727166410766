import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reportRecordStatus } from '../../../../types/reportRecordStatus'
import { getReportErrorScreenshotRequest } from '../../../../http/requests'
import { CloudDownloadRounded, ImageSearchTwoTone, PlayCircleFilled } from '@mui/icons-material'
import Loader from '../../../../components/Common/Loader/Loader'
import classNames from 'classnames'
import './ScanCompanyReportError.scss'

const ScanCompanyReportError = ({ report, company }) => {
  const [screenshot, setScreenshot] = useState(null)
  const [screenshotLoading, setScreenshotLoading] = useState(null)
  const { agencyDbName } = useSelector(({ download }) => download)

  const { t, i18n } = useTranslation('scan')

  const description =
    i18n.language === 'he'
      ? reportRecordStatus[report.status]?.description
      : reportRecordStatus[report.status]?.descriptionEn

  useEffect(() => {
    const fetchSreenshot = async () => {
      try {
        setScreenshotLoading(true)
        setScreenshot(null)

        const {
          data: { data },
        } = await getReportErrorScreenshotRequest({ reportRecordId: report.id, agency: agencyDbName })
        setScreenshot(data)
        setScreenshotLoading(false)
      } catch (error) {
        setScreenshotLoading(false)
        console.log(error)
      }
    }
    fetchSreenshot()
  }, [])

  return (
    <div className={classNames('scan-company-error-report', { 'no-screenshot': !screenshot })}>
      <main>
        <p>{description}</p>
        <h6>{t('errorReport.screenshotFromCompany')}</h6>
        <div className='download-error-modal-screenshot-placeholder'>
          {screenshotLoading ? (
            <Loader />
          ) : !screenshot ? (
            <>
              <ImageSearchTwoTone />
              <p>{t('errorReport.noScreenshot')}</p>
            </>
          ) : (
            <img src={screenshot} alt='error_screen' />
          )}
        </div>
      </main>
      <footer>
        {company.website && (
          <p>
            {t('errorReport.goToCompanySite')}
            <a href={company.website} target='_blank' rel='noopener noreferrer'>
              {company.website}
            </a>
          </p>
        )}
        <div>
          <a href={`./assets/_files/support/${company.companyId}.rar`} download>
            <CloudDownloadRounded />
            <span>{t('errorReport.trainingBooklet')}</span>
          </a>
          {report.youtube && (
            <a href={report.youtube}>
              <PlayCircleFilled />
              <span>{t('errorReport.tutorialVideo')}</span>
            </a>
          )}
        </div>
      </footer>
    </div>
  )
}

export default ScanCompanyReportError
