import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { ArrowDropDown, ArrowDropUp, BorderColor, CheckBox, CheckBoxOutlineBlank, Close } from '@mui/icons-material'
import ScanCompanyUpdateCredentials from '../ScanCompanyUpdateCredentials/ScanCompanyUpdateCredentials'
import { getCompanyAuthFields } from '../../../../utils/getAuthFields'
import { validationError } from '../../../../types/validationErrors'
import { hebrew } from '../../../../i18n'
import { createCompanyUserRequest, updateCompanyUserRequest } from '../../../../http/requests/agencyCompanyRequests'
import { cn } from '../../../../utils/stylesUtils'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { lowercaseFirstLetter } from '../../../../utils/charsUtils'
import './ScanCompanyRegisterUserModal.scss'

const ScanCompanyRegisterUserModal = ({ user, availableFields, company, onSuccessUpdate = () => {}, closeModal }) => {
  const { t } = useTranslation('scan')
  const [showUserNameInput, setShowUserNameInput] = useState(false)
  const [showReportTypesList, setShowReportTypesList] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [authFields, setAuthFields] = useState(
    getCompanyAuthFields(company).map(field => ({
      ...field,
      value: user ? user[lowercaseFirstLetter(field.fieldType)] : '',
      name: hebrew() ? field.name : field.nameEn,
    }))
  )
  const [errors, setErrors] = useState(
    getCompanyAuthFields(company).map(({ fieldType }) => ({
      fieldType,
      error: validationError.zeroLength,
    }))
  )
  const [insFields, setInsFields] = useState(user?.insuranceFieldsArray ?? availableFields.map(f => f.id))
  const [userName, setUserName] = useState(
    user?.name ?? `משתמש ${company.name}${company.users.length > 0 ? ' ' + (company.users.length + 1) : ''}`
  )

  const allFieldsSelected = availableFields.every(f => insFields.includes(f.id))
  const userNameInputRef = useRef()

  const selectInsField = field =>
    setInsFields(prev => (prev.includes(field) ? prev.filter(f => f !== field) : [...prev, field]))
  const selectAllFields = () => setInsFields(allFieldsSelected ? [] : availableFields.map(f => f.id))

  const setValidationErrors = authFields =>
    authFields.map(({ fieldType, value }) => ({
      fieldType,
      error: value === '' ? validationError.zeroLength : validationError.noError,
    }))

  const hasAuthFieldsErrors = errors.some(field => field.error !== validationError.noError)

  const submitDisabled = hasAuthFieldsErrors || insFields.length === 0 || submitLoading || userName.length === 0

  const cancelHandler = () => {
    closeModal()
  }

  const submitHandler = async () => {
    try {
      setSubmitLoading(true)
      const fields = {}
      const fieldsKeys = []
      authFields.forEach(af => {
        fields[af.fieldType] = af.value
        fieldsKeys.push(af.fieldType)
      })
      let formData = {
        name: userName,
        ...fields,
        authFields: fieldsKeys.join(','),
        insuranceFields: insFields.join(','),
        insuranceCompanyId: company.companyId,
        ...(user && { id: user.id }),
      }
      const request = user ? updateCompanyUserRequest : createCompanyUserRequest
      const {
        data: { data },
      } = await request(formData)
      setSubmitLoading(false)
      if (data) {
        onSuccessUpdate()
      }
    } catch (error) {
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    setErrors(setValidationErrors(authFields))
  }, [authFields])

  useEffect(() => {
    showUserNameInput && userNameInputRef.current.focus()
  }, [showUserNameInput])
  return (
    <ModalWindow renderPortal closeModal={closeModal} closeOnOutsideClick={false} showCloseIcon={false}>
      <div className='scan-company-register-modal'>
        <header>
          {showUserNameInput ? (
            <input
              ref={userNameInputRef}
              value={userName}
              onChange={e => setUserName(e.target.value)}
              placeholder='Add User Name'
              onBlur={() => setShowUserNameInput(false)}
            />
          ) : (
            <p className={cn({ 'no-name': !!!userName })} onClick={() => setShowUserNameInput(true)}>
              {userName || 'Add User Name'}
            </p>
          )}
          {!showUserNameInput && (
            <button onClick={() => setShowUserNameInput(true)}>
              <BorderColor fontSize='small' />
            </button>
          )}
        </header>
        <div>
          <ScanCompanyUpdateCredentials authFields={authFields} setAuthFields={setAuthFields} />
          <div className='report-types-selector-container'>
            <div className='report-types-selector' onClick={() => setShowReportTypesList(!showReportTypesList)}>
              <div>
                {insFields.length === 0 ? (
                  <p>{t(`companyUsers.selectReportTypesPlaceholder`)}</p>
                ) : (
                  insFields.map(f => (
                    <div key={f} className='report-type-chip'>
                      <span>{t(`companyUsers.reportTypeName${f}`)}</span>
                      <button
                        onClick={e => {
                          e.stopPropagation()
                          setInsFields(prev => prev.filter(insF => insF !== f))
                        }}
                      >
                        <Close />
                      </button>
                    </div>
                  ))
                )}
              </div>
              {showReportTypesList ? <ArrowDropUp /> : <ArrowDropDown />}
            </div>
            {showReportTypesList && (
              <div className='report-types-list'>
                {availableFields.length > 0 && (
                  <div>
                    <button onClick={selectAllFields}>
                      {allFieldsSelected ? (
                        <CheckBox style={{ color: 'var(--trend-up-color)' }} />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <span>{t(`companyUsers.allReportTypes`)}</span>
                    </button>
                  </div>
                )}
                {availableFields.map(f => (
                  <div key={f.id}>
                    <button onClick={() => selectInsField(f.id)}>
                      {insFields.includes(f.id) ? (
                        <CheckBox style={{ color: 'var(--trend-up-color)' }} />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <span>{t(`companyUsers.reportTypeName${f.id}`)}</span>
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <footer>
          <ButtonRounded onClick={cancelHandler} variant='outlined'>
            {t('companyUsers.cancelBtn')}
          </ButtonRounded>
          <ButtonRounded onClick={submitHandler} disabled={submitDisabled}>
            {t('companyUsers.saveBtn')}
          </ButtonRounded>
        </footer>
      </div>
    </ModalWindow>
  )
}

export default ScanCompanyRegisterUserModal
