import { useCallback, useEffect, useRef } from 'react'
import Loader from '../Loader/Loader'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import './ModalSidebar.scss'

const ModalSidebar = ({
  children,
  closeSidebar = () => {},
  closeOnClickOutSide = false,
  closeOnEsc = true,
  width = '600px',
  loadingOverlay = false,
  closeDisabled = false,
  renderPortal = true,
  contentLoading,
}) => {
  const bodyOverflowRef = useRef()
  const modalContentRef = useRef()
  const modalOverlayRef = useRef()

  const closeSidebarHandler = useCallback(() => {
    !closeDisabled && closeSidebar()
  }, [closeDisabled, closeSidebar])

  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        closeSidebarHandler()
      }
    },
    [closeSidebarHandler]
  )

  const content = (
    <div className='modal-sidebar-wrapper'>
      <div
        className='modal-sidebar-overlay'
        ref={modalOverlayRef}
        {...(closeOnClickOutSide && {
          onClick: () => closeSidebarHandler(),
        })}
      ></div>
      <div className='modal-sidebar' style={{ width }} ref={modalContentRef}>
        <div
          className={classNames('sidebar-close-btn', { disabled: closeDisabled })}
          onClick={() => closeSidebarHandler()}
        >
          <img src='./assets/close-icon/Close.png' className='img-icon' alt='close' />
        </div>
        <div className='modal-sidebar-content'>{contentLoading ? <Loader /> : children}</div>
        {loadingOverlay && (
          <div className='modal-sidebar-loading-overlay'>
            <Loader />
          </div>
        )}
      </div>
    </div>
  )

  useEffect(() => {
    if (closeOnEsc) {
      document.addEventListener('keydown', escFunction, false)
    }
    return () => {
      if (closeOnEsc) {
        document.removeEventListener('keydown', escFunction, false)
      }
    }
  }, [escFunction, closeOnEsc])

  useEffect(() => {
    bodyOverflowRef.current = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = bodyOverflowRef.current
    }
  }, [])

  if (renderPortal) {
    return createPortal(content, document.body)
  }

  return content
}

export default ModalSidebar
