import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { agencyHouseRoutes } from '../../../../environment/urls'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronLeft, ChevronRight, ExpandMore } from '@mui/icons-material'
import { hebrew } from '../../../../i18n'
import { getUserNameInitials } from '../../../../services/getUserNameInitials'
import { useEffect, useRef, useState } from 'react'
import DownloadSidebarCompanies from '../../../../components/DownloadSidebarCompanies/DownloadSidebarCompanies'
import Loader from '../../../../components/Common/Loader/Loader'
import { SCAN_RESET_ALL } from '../../../../types/actionTypesScan'
import './AgencyHouseDownloadSidebar.scss'

const AgencyHouseDownloadSidebar = () => {
  const [showCompanies, setShowCompanies] = useState({})
  const { Agencies, agenciesLoading } = useSelector(({ agencyHouseDownload }) => agencyHouseDownload)
  const { t } = useTranslation('download')
  const tSidebar = key => t(`sidebar.${key}`)
  const dispatch = useDispatch()

  const { pathname } = useLocation()
  const prevAgencyDbName = useRef()

  useEffect(() => {
    const [currAgencyDbName] = pathname
      .replace(agencyHouseRoutes.download, '')
      .split('/')
      .filter(p => p.length > 0)

    if (currAgencyDbName && currAgencyDbName !== prevAgencyDbName.current) {
      prevAgencyDbName.current = currAgencyDbName
      dispatch({ type: SCAN_RESET_ALL })
    }
  }, [pathname])

  useEffect(() => {
    const [currAgencyDbName, companyId] = pathname
      .replace(agencyHouseRoutes.download, '')
      .split('/')
      .filter(p => p.length > 0)
    const showCompaniesDbName = Object.keys(showCompanies)[0]
    if (!currAgencyDbName || (currAgencyDbName !== showCompaniesDbName && !companyId)) {
      setShowCompanies({})
    } else if (currAgencyDbName && companyId) {
      setShowCompanies({ [currAgencyDbName]: true })
    }
  }, [pathname, Agencies])

  if (agenciesLoading)
    return (
      <aside className='agency-house-download-sidebar'>
        <h4>{tSidebar('heading')}</h4>
        <Loader />
      </aside>
    )

  return (
    <aside className='agency-house-download-sidebar'>
      <h4>{tSidebar('heading')}</h4>
      <NavLink
        to={agencyHouseRoutes.download}
        exact
        activeClassName='current-link'
        isActive={(match, location) => {
          if (!match) {
            return false
          }
          return true
        }}
      >
        <p>{tSidebar('allAgencyReports')}</p>
        {/* <ReportsCountBadge count={AvailableReports} /> */}
      </NavLink>
      <ul>
        {Agencies.map(a => (
          <li key={a.Id}>
            <>
              <NavLink
                to={`${agencyHouseRoutes.download}/${a.DbName}`}
                activeClassName='current-link'
                isActive={(match, location) => {
                  if (!match) {
                    return false
                  }
                  return true
                }}
              >
                <div>
                  <div style={{ background: a.AvatarBackgroundColor }}>{getUserNameInitials(a.Name)}</div>
                  <p>{a.Name}</p>
                </div>
                <div>
                  {/* <CountBadge count={a.AvailableReports} /> */}
                  {[...a.NoAttempts, ...a.AllFailed, ...a.SomeSucceed, ...a.AllSucceed].length > 0 && (
                    <button onClick={() => setShowCompanies(prev => ({ [a.DbName]: !prev[a.DbName] }))}>
                      {showCompanies[a.DbName] ? <ExpandMore /> : hebrew() ? <ChevronLeft /> : <ChevronRight />}
                    </button>
                  )}
                </div>
              </NavLink>
              {showCompanies[a.DbName] && (
                <DownloadSidebarCompanies
                  companies={[...a.NoAttempts, ...a.AllFailed, ...a.SomeSucceed, ...a.AllSucceed]}
                  baseRoute={`${agencyHouseRoutes.download}/${a.DbName}`}
                  agencyDbName={a.DbName}
                />
              )}
            </>
          </li>
        ))}
      </ul>
    </aside>
  )
}

export default AgencyHouseDownloadSidebar
