import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomerGroups } from '../../actions/customerActions'
import { hebrew } from '../../../../i18n'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import CustomersGroupDetailsSidbar from '../../../customers/components/CustomersGroupDetailsSidbar/CustomersGroupDetailsSidbar'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Loader from '../../../../components/Common/Loader/Loader'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import CustomerGroupsMemberItem from '../CustomerGroupsMemberItem/CustomerGroupsMemberItem'
import { deleteCustomersGroupCustomerRequest } from '../../../../http/requests'
import './CustomerGroups.scss'

const CustomerGroups = () => {
  const { id } = useParams()
  const {
    customerGroups,
    customerGroupsLoading,
    groupTypes,
    customerInfo: customer,
  } = useSelector(({ customer }) => customer)
  const { t } = useTranslation('customer')
  const { t: tCustomers } = useTranslation('customers')
  const dispatch = useDispatch()

  const [showAddCustomerGroupModal, setShowAddCustomerGroupModal] = useState(false)
  const [showDetailsSidebar, setShowDetailsSidebar] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [showMembers, setShowMembers] = useState({})

  const showMembersHandler = grId => {
    setShowMembers(prev => ({ ...prev, [grId]: !prev[grId] }))
  }

  const getGroupTypeName = typeId => {
    const groupType = groupTypes.find(gr => gr.id === typeId)
    return hebrew() ? groupType?.name : groupType?.englishName
  }

  const removeCustomer = async (group, customerId) => {
    const { success } = await deleteCustomersGroupCustomerRequest(group.id, customerId)
    success && dispatch(getCustomerGroups(id))
  }

  const openAddGroupModal = () => {
    setShowAddCustomerGroupModal(true)
  }

  const openDetailsSidebar = group => {
    setSelectedGroup(group)
    setShowDetailsSidebar(true)
  }

  const closeDetailsSidebar = () => {
    setShowDetailsSidebar(false)
    setSelectedGroup(null)
  }

  const fetchCustomerGroups = useCallback(() => {
    dispatch(getCustomerGroups(id))
  }, [dispatch, id])

  return (
    <>
      <div className='customer-groups-wrapper'>
        <div className='customer-groups-container-icon'>
          <img src='./assets/person-family/Category.png' alt='grps' />
        </div>
        <div className='customer-groups-container'>
          <div className='customer-groups-title'>
            <div>{t('customerGroupsTitle')}</div>
            <div>
              {customer.active && (
                <button onClick={openAddGroupModal}>
                  <img src='./assets/icon-add/Add.png' alt='add' />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {customerGroupsLoading && <Loader />}
      {customerGroups.map(grt => (
        <div key={grt.groupName} className='customer-group-type-container'>
          <p>{getGroupTypeName(+grt.groupName)}</p>
          <div className='customer-groups-list'>
            {grt.groupItems.map(gr => (
              <div key={gr.id} className='customer-groups-list-item'>
                <div className='customer-groups-list-summary-item'>
                  <p className='customer-group-name'>
                    {gr.name ?? tCustomers('customersGroupNamePlaceholder', { groupId: gr.id })}{' '}
                    <span>({gr.customers.length})</span>
                  </p>
                  <div className='customer-group-actions'>
                    <button onClick={() => showMembersHandler(gr.id)}>
                      {showMembers[gr.id] ? <ArrowDropUp /> : <ArrowDropDown />}
                    </button>
                    <button onClick={() => openDetailsSidebar(gr)}>
                      <img src='./assets/export/Export@1.1x.png' alt='edit' />
                    </button>
                  </div>
                </div>
                {showMembers[gr.id] &&
                  gr.customers.map(c => (
                    <CustomerGroupsMemberItem key={c.id} customer={c} removeCustomer={() => removeCustomer(gr, c.id)} />
                  ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      {showAddCustomerGroupModal && (
        <AddCustomerGroupModal
          closeModal={() => setShowAddCustomerGroupModal(false)}
          groupCustomers={[customer]}
          onAddSuccess={fetchCustomerGroups}
        />
      )}
      {showDetailsSidebar && (
        <CustomersGroupDetailsSidbar
          groupId={selectedGroup?.id}
          closeSidebar={closeDetailsSidebar}
          onEditSuccess={fetchCustomerGroups}
        />
      )}
    </>
  )
}

export default CustomerGroups
