import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PaymentCalc from '../PaymentCalc/PaymentCalc'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import ModalPaymentAgreement from '../../../../components/ModalPaymentAgreement/ModalPaymentAgreement'
import PaymentFrameModal from '../../../../components/PaymentFrameModal/PaymentFrameModal'
import { useHistory } from 'react-router'
import ButtonBack from '../../../../components/ButtonBack/ButtonBack'
import { addAgencyCompanies } from '../../../../http/requests'
import './StepPaymentOptions.scss'

const StepPaymentOptions = ({ prevStep, data, setHideRegisterStep }) => {
  const { t } = useTranslation('registration')
  const history = useHistory()

  let { selectedCompanies } = data

  const [agreedTerms, setAgreedTerms] = useState(false)
  const [showAgreement, setShowAgreement] = useState(false)
  const [showPaymentFrame, setShowPaymentFrame] = useState(false)

  const addCompanies = async () => {
    const companies = selectedCompanies
      .filter(c => c.insuranceFields.some(f => f.selected))
      .map(c => {
        return {
          ...c,
          insuranceFields: c.insuranceFields.filter(f => f.selected).map(f => f.id),
        }
      })
    const { data: addData } = await addAgencyCompanies(companies)
    if (!addData.hasError) setShowPaymentFrame(true)
  }

  const agreeTermsHandler = async () => {
    setAgreedTerms(true)
    setShowAgreement(false)
    await addCompanies()
  }

  const clickNextHandler = async () => {
    if (!agreedTerms) {
      setShowAgreement(true)
    } else {
      await addCompanies()
    }
  }

  const onPaymentSuccess = () => {
    history.push('/download')
  }

  return (
    <>
      <div
        className='step-payment'
        style={{
          backgroundImage: `url('assets/_register/step-payment/bg.png')`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <PaymentCalc />
        <div className='step-payment-footer'>
          <ButtonBack onClick={prevStep} />
          <ButtonRounded onClick={clickNextHandler} size='large'>
            {t('stepPayment.nextBtn')}
          </ButtonRounded>
        </div>
      </div>
      {showAgreement && (
        <ModalPaymentAgreement
          closeModal={() => setShowAgreement(false)}
          confirmAgreement={agreeTermsHandler}
          agreementConfirmed={agreedTerms}
        />
      )}
      {showPaymentFrame && (
        <PaymentFrameModal closeModal={() => setShowPaymentFrame(false)} onPaymentSuccess={onPaymentSuccess} />
      )}
    </>
  )
}

export default StepPaymentOptions
