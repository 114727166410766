import { useTranslation } from 'react-i18next'
import ScanDatepicker from '../ScanDatepicker/ScanDatepicker'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { useDispatch, useSelector } from 'react-redux'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import ScanDownloadedRadialChart from '../ScanDownloadedRadialChart/ScanDownloadedRadialChart'
import ScanHistorySidebar from '../ScanHistorySidebar/ScanHistorySidebar'
import CountBadge from '../../../../components/CountBadge/CountBadge'
import { hebrew } from '../../../../i18n'
import { NavLink, useHistory, useParams } from 'react-router-dom'
import { agencyHouseRoutes, agencyRoutes } from '../../../../environment/urls'
import { latestMonthSelected, scanSelectedMonth } from '../../reducers/scanSelectors'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import Loader from '../../../../components/Common/Loader/Loader'
import { getReportRecordsMonthStatus } from '../../actions/scanActions'
import './ScanTotals.scss'

const ScanTotals = () => {
  const { agencyDbName } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation('scan')
  const {
    dates,
    monthStatus: { downloadPercent, downloadedReports, notDownloadedReports, companies },
    monthStatusLoading,
  } = useSelector(({ scan }) => scan)

  const companyToScan = companies.find(c => c.availableReportsCount > 0)?.id

  const [showHistory, setShowHistory] = useState(false)
  let banner = null
  if (!latestMonthSelected()) {
    banner = <PrevMonthWarnBanner text={t('totals.prevMonthWarnBanner')} />
  } else if (downloadedReports > 0 && notDownloadedReports > 0) {
    banner = <SomeDownloadedWarnBanner text={t('totals.someDownloadedWarnBanner')} />
  } else if (downloadedReports === 0) {
    banner = <NoDownloadsWarnBanner text={t('totals.noDownloadsWarnBanner')} />
  }

  useEffect(() => {
    if (agencyDbName && scanSelectedMonth()) {
      dispatch(getReportRecordsMonthStatus({ month: scanSelectedMonth(), agency: agencyDbName }))
    }
  }, [agencyDbName, dates, dispatch])

  return (
    <>
      <div className='scan-totals-container'>
        <header>
          <h5>{t('totals.heading')}</h5>
          <ScanDatepicker />
        </header>
        <div className='scan-totals-content'>
          {monthStatusLoading ? (
            <Loader />
          ) : (
            <>
              <div className='scan-totals-banner-container'>{banner}</div>
              <section className={classNames({ 'all-downloaded': notDownloadedReports === 0 })}>
                {notDownloadedReports > 0 ? (
                  <div className='scan-totals-card pending'>
                    <header>
                      <p>{t('totals.pendingHeading')}</p>
                      {companyToScan && (
                        <ButtonRounded
                          onClick={() =>
                            history.push(
                              (agencyDbName ? agencyHouseRoutes.download + '/' + agencyDbName : agencyRoutes.scan) +
                                '/' +
                                companyToScan
                            )
                          }
                        >
                          {t('totals.scanBtn')}
                        </ButtonRounded>
                      )}
                    </header>
                    <div className='scan-totals-card-content'>
                      <div className='scan-totals-card-summary'>
                        <p>{notDownloadedReports}</p>
                        <p>{t('totals.reportsAvailable')}</p>
                      </div>
                      <div className='scan-totals-card-companies'>
                        {companies
                          .filter(c => c.availableReportsCount)
                          .map(c => (
                            <NavLink
                              to={`${
                                agencyDbName ? agencyHouseRoutes.download + '/' + agencyDbName : '/' + agencyRoutes.scan
                              }/${c.id}`}
                              key={c.id}
                              className='scan-totals-card-company'
                            >
                              <div>
                                <img src={`./assets/companies-logos-light/${c.id}.png`} alt={c.name} />
                                <p>{hebrew() ? c.name : c.nameEn}</p>
                              </div>
                              <CountBadge count={c.availableReportsCount} />
                            </NavLink>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='scan-totals-card all-downloaded'>
                    <img src='./assets/_scan/all-downloaded-icon.png' alt='all' />
                    <p>{t('totals.allDownloaded')}</p>
                  </div>
                )}
                <div className='scan-totals-card downloaded'>
                  <header>
                    <p>{t('totals.downloadedHeading')}</p>
                    <ButtonText onClick={() => setShowHistory(true)}>{t('totals.detailsBtn')}</ButtonText>
                  </header>
                  <div className='scan-totals-card-radial-bar'>
                    <ScanDownloadedRadialChart percents={downloadPercent} />
                  </div>
                  <div className='scan-totals-card-radial-bar-values'>
                    <p>{downloadedReports}</p>
                    <p>{t('totals.reportsDownloaded')}</p>
                  </div>
                  <p
                    className='scan-totals-card-downloaded-banner'
                    style={{ visibility: downloadedReports ? 'visible' : 'hidden' }}
                  >
                    {t('totals.percentsBanner', { percents: downloadPercent })}
                  </p>
                </div>
              </section>
            </>
          )}
        </div>
      </div>
      {showHistory && <ScanHistorySidebar closeSidebar={() => setShowHistory(false)} agency={agencyDbName} />}
    </>
  )
}

export default ScanTotals

const PrevMonthWarnBanner = ({ text }) => (
  <div className='scan-totals-warn-banner prev-month'>
    <p>{text}</p>
  </div>
)

const SomeDownloadedWarnBanner = ({ text }) => (
  <div className='scan-totals-warn-banner some-downloaded'>
    <img src='./assets/_scan/some-downloaded-icon.png' alt='downloaded' />
    <p>{text}</p>
  </div>
)

const NoDownloadsWarnBanner = ({ text }) => (
  <div className='scan-totals-warn-banner no-downloads'>
    <img src='./assets/_scan/no-downloads-icon.png' alt='no-downloads' />
    <p>{text}</p>
  </div>
)
