import { NavLink, useLocation } from 'react-router-dom'
import { bigAgencyPrivateRoutes, regularAgencyPrivateRoutes } from '../../../../routes/routes'
import { agencyHouseAnalyticsSubroute, agencyHouseRoutes } from '../../../../environment/urls'
import { hebrew } from '../../../../i18n'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import './AgencyHouseAnalyticsNavbar.scss'

const AgencyHouseAnalyticsNavbar = () => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const agencyHouseAnalyticsRoute = (
    isRegularOrDeltaAgency() ? regularAgencyPrivateRoutes : bigAgencyPrivateRoutes
  ).find(r => r.path === agencyHouseRoutes.analytics)
  const [incomesOpen, setIncomesOpen] = useState(true)
  const [policiesOpen, setpolicesOpen] = useState(true)
  const { pathname } = useLocation()

  useEffect(() => {
    const incomesPath = pathname.includes(agencyHouseAnalyticsSubroute.incomes)
    const policesPath = pathname.includes(agencyHouseAnalyticsSubroute.policies)
    incomesPath && setIncomesOpen(true)
    policesPath && setpolicesOpen(true)
  }, [pathname])

  const navbarItem = (analitycsSubroute, showIcon) => (
    <li>
      <NavLink
        to={agencyHouseAnalyticsRoute.path + agencyHouseAnalyticsRoute.subroute[analitycsSubroute].path}
        activeClassName='current'
      >
        {showIcon && (
          <img
            src={`./assets/navbar-icons/analytics-${agencyHouseAnalyticsRoute.subroute[
              analitycsSubroute
            ].nameEn.toLowerCase()}.png`}
            alt=''
            className='img-icon'
          />
        )}
        <span>
          {hebrew()
            ? agencyHouseAnalyticsRoute.subroute[analitycsSubroute].name
            : agencyHouseAnalyticsRoute.subroute[analitycsSubroute].nameEn}
        </span>
      </NavLink>
    </li>
  )

  return (
    <div className='agency-house-analytics-navbar'>
      <nav>
        <ul>
          <li>
            <AgencyHouseAnalyticsNavbarGroup
              name={t('incomes')}
              open={incomesOpen}
              setOpen={() => setIncomesOpen(!incomesOpen)}
              //iconPath={`./assets/navbar-icons/analytics-incomes.png`}
            >
              {navbarItem(agencyHouseAnalyticsSubroute.incomes, true)}
              {navbarItem(agencyHouseAnalyticsSubroute.incomesNew, true)}
            </AgencyHouseAnalyticsNavbarGroup>
          </li>
          <li>
            <AgencyHouseAnalyticsNavbarGroup
              name={t('policies')}
              open={policiesOpen}
              setOpen={() => setpolicesOpen(!policiesOpen)}
              //iconPath={`./assets/navbar-icons/analytics-policies.png`}
            >
              {navbarItem(agencyHouseAnalyticsSubroute.policies, true)}
              {navbarItem(agencyHouseAnalyticsSubroute.policiesNew, true)}
            </AgencyHouseAnalyticsNavbarGroup>
          </li>
          {navbarItem(agencyHouseAnalyticsSubroute.customers, true)}
          {!isRegularOrDeltaAgency() && navbarItem(agencyHouseAnalyticsSubroute.signals, true)}
          {isRegularOrDeltaAgency() && navbarItem(agencyHouseAnalyticsSubroute.rating, true)}
        </ul>
      </nav>
    </div>
  )
}

export default AgencyHouseAnalyticsNavbar

const AgencyHouseAnalyticsNavbarGroup = ({ name, open, setOpen, children, iconPath }) => (
  <div className={`agency-house-analytics-navbar-group`}>
    <p>
      {iconPath && <img src={iconPath} alt='' className='img-icon' />}
      <span>{name}</span>
    </p>
    <ul>{children}</ul>
  </div>
)
