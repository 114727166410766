import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import AgencyHouseLobbySignalCard from './AgencyHouseLobbySignalCard'
import { useDispatch } from 'react-redux'
import { POLICIES_SIGNALS_FILTER_APPLY } from '../../../../types/actionTypes'
import { irregularSignal } from '../../../../types/irregularSignals'
import { useHistory } from 'react-router'
import { agencyPoliciesSubroute, agencyRoutes } from '../../../../environment/urls'
import { isRegularOrDeltaAgency } from '../../../login/reducers/loginSelectors'
import './AgencyHouseLobbySignals.scss'

const AgencyHouseLobbySignals = ({ signals, signalsLoading }) => {
  const ref = useRef()
  const { t } = useTranslation('agencyHouseLobby')
  const history = useHistory()

  const dispatch = useDispatch()

  useEffect(() => {
    ref.current.scrollIntoView()
  }, [])

  return (
    <div className='agency-house-lobby-signals' ref={ref}>
      <header>
        <h5>{t('signalsHeader')}</h5>
      </header>
      <section>
        {signalsLoading ? (
          <Loader />
        ) : Object.entries(signals).length === 0 ? (
          <div className='no-signals-data'>{t('noSignals')}</div>
        ) : (
          Object.entries(signals).map(([k, s]) => (
            <AgencyHouseLobbySignalCard
              key={k}
              signalKey={k}
              count={s.count}
              value={s.value}
              {...(isRegularOrDeltaAgency() &&
                Object.values(irregularSignal).find(s => s.key === k).irregularReasons && {
                  onCardClick: () => {
                    const signalId = Object.values(irregularSignal).find(s => s.key === k).id
                    dispatch({
                      type: POLICIES_SIGNALS_FILTER_APPLY,
                      payload: { signalId, checked: true },
                    })
                    history.push(`/${agencyRoutes.policies}${agencyPoliciesSubroute.signals}?type=${signalId}`)
                  },
                })}
            />
          ))
        )}
      </section>
    </div>
  )
}

export default AgencyHouseLobbySignals
