import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAssociatedAgenciesRequest } from '../../../../http/requests/agencyHouseRequests'
import AgenciesListContainer from '../AgenciesListContainer/AgenciesListContainer'
import AgenciesListItem from '../AgenciesListItem/AgenciesListItem'
import ButtonCheckbox from '../../../../components/Common/ButtonCheckbox/ButtonCheckbox'
import {
  agencyApprovalStatus,
  agencyApprovalStatusType,
  agencyExistType,
  agencyPayment,
  agencyPaymentType,
} from '../../../../types/agencyTypes'
import { hebrew } from '../../../../i18n'
import NoData from '../../../../components/Common/NoData/NoData'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import { formatAsPhoneNumber } from '../../../../utils/formatAs'
import AssociatedAgencyDetailsSidebar from '../AssociatedAgencyDetailsSidebar/AssociatedAgencyDetailsSidebar'
import { echo } from '../../../../utils/consoleUtils'
import { resendAgencyInviteRequest } from '../../../../http/requests/agencyHouseRequests'
import LoadingOverlay from '../../../../components/Common/LoadingOverlay/LoadingOverlay'
import './AssociatedAgencies.scss'

const AssociatedAgencies = () => {
  const { t } = useTranslation('agencyHouse')
  const { agencyPaymentFilter, agencyRequestStatusFilter } = useSelector(({ agentHouse }) => agentHouse)
  const [agencies, setAgencies] = useState([])
  const [agenciesLoading, setAgenciesLoading] = useState(true)
  const [detailsAgencyId, setDetailsAgencyId] = useState(null)
  const [resendInviteLoading, setResendInviteLoading] = useState({})

  const paymentFilter = agencyPaymentFilter.find(f => f.checked)
  const statusFilter = agencyRequestStatusFilter.find(f => f.checked)

  const resendInvite = async agencyId => {
    try {
      setResendInviteLoading(prev => ({ ...prev, [agencyId]: true }))
      const {
        data: { Data },
      } = await resendAgencyInviteRequest(agencyId)
      if (Data) {
        setAgencies(prev =>
          prev.map(a => ({
            ...a,
            ConnectionStatus:
              a.AgencyId === agencyId ? agencyApprovalStatusType.waitingForApproval : a.ConnectionStatus,
          }))
        )
      }
      setResendInviteLoading(prev => ({ ...prev, [agencyId]: false }))
    } catch (error) {
      setResendInviteLoading(prev => ({ ...prev, [agencyId]: false }))
      echo.log(echo.asWarning(`Error Resend`), echo.asAlert(error))
    }
  }

  const fetchAgencies = useCallback(
    async isCancelled => {
      try {
        setAgenciesLoading(true)
        const filters = {
          ...(paymentFilter && { IsPaidByAssociation: paymentFilter.id === agencyPaymentType.byAgency }),
          ...(statusFilter && { ConnectionStatus: statusFilter.id }),
        }
        const {
          data: { Data },
        } = await getAssociatedAgenciesRequest(filters)
        if (!isCancelled) {
          setAgenciesLoading(false)
          setAgencies(Data)
        }
      } catch (error) {
        if (!isCancelled) {
          setAgenciesLoading(false)
        }
      }
    },
    [paymentFilter, statusFilter]
  )

  useEffect(() => {
    let isCancelled = false
    fetchAgencies(isCancelled)
    return () => {
      isCancelled = true
    }
  }, [fetchAgencies])

  return (
    <>
      <AgenciesListContainer
        listLoading={agenciesLoading}
        noItems={agencies.length === 0 && !paymentFilter && !statusFilter}
        addAgencyType={agencyExistType.existing}
        onAssociateSuccess={fetchAgencies}
      >
        {agencies.length === 0 && (
          <div className='no-agencies-found'>
            <NoData text='Nothing Found' />
          </div>
        )}
        {agencies.length > 0 && (
          <>
            <div className='agencies-list-heading'>
              <div>
                <ButtonCheckbox />
              </div>
              <div className='associated-agency-item-grid'>
                <div>{t('agenciesListHeadings.fullName')}</div>
                <div>{t('agenciesListHeadings.agentPhone')}</div>
                <div>{t('agenciesListHeadings.associatedAgencyName')}</div>
                <div>{t('agenciesListHeadings.email')}</div>
                <div>{t('agenciesListHeadings.licenseAgentNumber')}</div>
                <div>{t('agenciesListHeadings.payments')}</div>
                <div>{t('agenciesListHeadings.requestStatus')}</div>
              </div>
              <div></div>
            </div>
            <div className='agencies-list'>
              {agencies.map(a => (
                <AgenciesListItem key={a.AgencyId} onClick={() => setDetailsAgencyId(a.AgencyId)}>
                  {resendInviteLoading[a.AgencyId] && <LoadingOverlay />}
                  <div className='associated-agency-item-grid'>
                    <div>{a.UserName}</div>
                    <div className='agent-phone-number'>{formatAsPhoneNumber(a.UserPhone)}</div>
                    <div>{a.AgencyName}</div>
                    <div>{a.UserEmail}</div>
                    <div>{a.AgencyLicenseNumber}</div>
                    <div>
                      {a.IsPaidByAssociation
                        ? hebrew()
                          ? agencyPayment[agencyPaymentType.byAgency].name
                          : agencyPayment[agencyPaymentType.byAgency].nameEn
                        : hebrew()
                        ? agencyPayment[agencyPaymentType.byAgent].name
                        : agencyPayment[agencyPaymentType.byAgent].nameEn}
                    </div>
                    <div className='approval-status'>
                      <div>
                        {agencyApprovalStatus[a.ConnectionStatus].icon}
                        <p>
                          {hebrew()
                            ? agencyApprovalStatus[a.ConnectionStatus].name
                            : agencyApprovalStatus[a.ConnectionStatus].nameEn}
                        </p>
                      </div>
                      {a.ConnectionStatus === agencyApprovalStatusType.denied && (
                        <ButtonText
                          onClick={e => {
                            e.stopPropagation()
                            resendInvite(a.AgencyId)
                          }}
                        >
                          {t('resendInviteBtn')}
                        </ButtonText>
                      )}
                    </div>
                  </div>
                </AgenciesListItem>
              ))}
            </div>
          </>
        )}
      </AgenciesListContainer>
      {detailsAgencyId && (
        <AssociatedAgencyDetailsSidebar agencyId={detailsAgencyId} closeSidebar={() => setDetailsAgencyId(null)} />
      )}
    </>
  )
}

export default AssociatedAgencies
