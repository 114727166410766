import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import './ScanHistorySidebarItem.scss'
import { hebrew } from '../../../../i18n'
import { Fragment } from 'react'
import { ArrowDropDownTwoTone, ArrowDropUpTwoTone } from '@mui/icons-material'
import { reportRecordStatus, reportRecordStatusType } from '../../../../types/reportRecordStatus'
import { cn } from '../../../../utils/stylesUtils'

const getDate = ({ date, inclTime = false }) => {
  const [year, month, day, time] = date.split(/-|T/)
  return `${inclTime ? `${time.slice(0, 5)}` : ''}${inclTime ? ' ,' : ''}${[day, month, year].join('.')}`
}

const errorReport = status => status !== reportRecordStatusType.saved && status !== reportRecordStatusType.none

const ScanHistorySidebarItem = ({ item }) => {
  const { t } = useTranslation('scan')
  const [showReports, setShowReports] = useState([])
  const companies = Object.values(
    item.records.reduce(
      (prev, curr) => ({
        ...prev,
        [curr.insuranceCompanyId]: {
          name: prev[curr.insuranceCompanyId]?.name ?? curr.companyName,
          nameEn: prev[curr.insuranceCompanyId]?.nameEn ?? curr.englishCompanyName,
          id: prev[curr.insuranceCompanyId]?.id ?? curr.insuranceCompanyId,
          records: [...(prev[curr.insuranceCompanyId]?.records || []), curr],
        },
      }),
      {}
    )
  )
  const reportsSummary = reports => {
    let available = reports.filter(r => !r.isDisabled).length
    let downloaded = reports.filter(r => !r.isDisabled && r.status === reportRecordStatusType.saved).length
    let failed = reports.filter(
      r => !r.isDisabled && r.status !== reportRecordStatusType.saved && r.status !== reportRecordStatusType.none
    ).length
    let waiting = reports.filter(r => !r.isDisabled && r.status === reportRecordStatusType.none).length
    let disabled = reports.filter(r => r.isDisabled).length
    let downloadedText = downloaded > 0 ? t('history.reportsDownloaded', { reportsCount: downloaded }) : null
    let failedText = failed > 0 ? t('history.reportsFailed', { reportsCount: failed }) : null
    let waitingText = waiting > 0 ? t('history.reportsWaiting', { reportsCount: waiting }) : null
    let disabledText = disabled > 0 ? t('history.reportsDisabled', { reportsCount: disabled }) : null
    return {
      text: [downloadedText, failedText, waitingText, disabledText].filter(v => v).join(', '),
      status: failed > 0 ? 'error' : available === downloaded ? 'saved' : 'waiting',
    }
  }

  return (
    <div className='scan-history-sidebar-item'>
      <div className='scan-history-sidebar-item-header'>
        <p>{getDate({ date: item.month, inclTime: false })}</p>
      </div>
      <div className='scan-history-sidebar-item-companies'>
        {companies.map(c => (
          <Fragment key={c.id}>
            <div className='scan-history-sidebar-item-company'>
              <div key={c.id} className='scan-history-sidebar-item-company-header'>
                <div>
                  <img src={`./assets/companies-logos-light/${c.id}.png`} alt={c.name} />
                  <p>{hebrew() ? c.name : c.nameEn}</p>
                </div>
                <button
                  onClick={() =>
                    setShowReports(
                      showReports.includes(c.id) ? [...showReports.filter(p => p !== c.id)] : [...showReports, c.id]
                    )
                  }
                >
                  {showReports.includes(c.id) ? <ArrowDropUpTwoTone /> : <ArrowDropDownTwoTone />}
                </button>
              </div>
              <div className='scan-history-sidebar-item-records'>
                {showReports.includes(c.id) ? (
                  c.records.map(r => (
                    <div key={r.id} className='scan-history-sidebar-item-record'>
                      <div>
                        <div
                          className={cn('record-status-chip', {
                            disabled: r.isDisabled,
                            saved: r.status === reportRecordStatusType.saved,
                            error: !r.isDisabled && errorReport(r.status),
                            waiting: !r.isDisabled && r.status === reportRecordStatusType.none,
                          })}
                        ></div>
                        <p>{getDate({ date: r.updateDate ?? r.month, inclTime: r.updateDate })}</p>
                      </div>
                      <div>
                        <p>{r.reportName}</p>
                        {(r.isDisabled || errorReport(r.status)) && (
                          <p className={cn({ disabled: r.isDisabled, error: errorReport(r.status) })}>
                            {r.isDisabled
                              ? t('downloadDisabled')
                              : hebrew()
                              ? reportRecordStatus[r.status].title
                              : reportRecordStatus[r.status].titleEn}
                          </p>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='scan-history-sidebar-item-record'>
                    <div className='summary'>
                      <div className={cn('record-status-chip', reportsSummary(c.records).status)}></div>
                      <p>{reportsSummary(c.records).text}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export default ScanHistorySidebarItem
