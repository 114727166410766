import classNames from 'classnames'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { createPayment, updatePaymentSubscription } from '../../http/requests'
import { cookieGetItemValue } from '../../services/cookieStorgeService'
import { SHARED_SET_NO_SUBSCRIPTION } from '../../types/actionTypesShared'
import { paymentPeriod, paymentPlanType } from '../../types/paymentPlanTypes'
import Button from '../Common/Button/Button'
import Loader from '../Common/Loader/Loader'
import './PaymentIframe.scss'

const PaymentIframe = ({
  onPaymentSuccess = () => {},
  successSubmitHandler = () => {},
  subscription = null,
  updateSubscription = false,
}) => {
  const { noSubscriptionFound } = useSelector(state => state.shared)

  const [frameData, setFrameData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('Error occured')
  const [success, setSuccess] = useState(null)

  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const createPaymentHanlder = async () => {
    try {
      setError(null)
      setSuccess(null)
      setLoading(true)
      const subscriptionTypeId = subscription?.type ?? subscription?.TypeId
      let isYearSubscription = null
      if (subscription) {
        isYearSubscription = subscription?.period === paymentPeriod.yearly
      }
      const {
        data: { Data },
      } = await createPayment(subscriptionTypeId, isYearSubscription)
      setFrameData({ processId: Data.ProcessId, processToken: Data.ProcessToken, url: Data.Url })
    } catch (error) {
      console.log(error)
      setError('Server is unavailable')
    } finally {
      setLoading(false)
    }
  }

  const paymentEventHandler = async result => {
    const { origin: iframeOrigin } = new URL(frameData.url)
    if (
      result.origin === 'https://meshulam.co.il' ||
      result.origin === 'https://sandbox.meshulam.co.il' ||
      result.origin === 'https://secure.meshulam.co.il' ||
      result.origin === iframeOrigin
    ) {
      let erorrMessage = null
      let successMessage = null
      switch (result.data.action) {
        case 'close': {
          console.log('closing frame')
          erorrMessage = 'Payment was cancelled'
          break
        }
        case 'payment': {
          if (result.data.status == 1) {
            console.log('payment success')
            successMessage = 'payment success'
          }
          if (result.data.status == 0) {
            console.log('payment error')
            erorrMessage = 'Payment Error'
          }
          break
        }
        case 'failed_to_load_page': {
          erorrMessage = 'Failed to connect to payment system'
          break
        }
        default:
          console.log('default')
      }
      if (erorrMessage) {
        setError(erorrMessage)
      } else {
        let paymentResult = null
        let isYearSubscription = null
        if (subscription) {
          isYearSubscription = subscription?.period === paymentPeriod.yearly
        }
        const agencyName = cookieGetItemValue('agencyDnsName')
        const subscriptionTypeId = subscription?.type
        const subscriptionCompanyId = subscription?.companyId

        if (!updateSubscription) {
          const {
            data: { Data },
          } = await updatePaymentSubscription({
            ...frameData,
            isYearSubscription,
            agencyName,
            subscriptionTypeId,
            subscriptionCompanyId,
          })
          paymentResult = Data
        } else {
          const {
            data: { Data },
          } = await updatePaymentSubscription({
            ...frameData,
            isYearSubscription,
            subscriptionTypeId,
            subscriptionCompanyId,
          })
          paymentResult = Data
        }
        console.log(paymentResult)
        if (paymentResult) {
          setSuccess(successMessage)
          if (noSubscriptionFound) {
            dispatch({ type: SHARED_SET_NO_SUBSCRIPTION, payload: false })
          }
          onPaymentSuccess()
        } else {
          setError('Something went wrong')
        }
      }
    }
  }

  useEffect(() => {
    const buildPayment = async () => await createPaymentHanlder()
    buildPayment()
  }, [])

  useEffect(() => {
    if (frameData) {
      window.addEventListener('message', paymentEventHandler)
      return () => {
        window.removeEventListener('message', paymentEventHandler)
      }
    }
  }, [frameData])

  return (
    <div className='payment-iframe-container'>
      {loading ? (
        <Loader />
      ) : error || success ? (
        <div className={classNames('payment-iframe-placeholder', { error })}>
          {/* <div className='payment-plans-modal-icon'>
            <img src='./assets/cards-pack/cards-pack.png' alt='ico' />
          </div> */}
          {error && (
            <>
              <h4>{t('paymentFailed')}</h4>
              <h5>{t('paymentDetailsFailed')}</h5>
              <div>
                <Button caption={t('paymentModalTryBtn')} onClick={createPaymentHanlder} />
              </div>
            </>
          )}
          {success && (
            <>
              <h4>{t('paymentSuccess')}</h4>
              {subscription?.type !== paymentPlanType.trial && <h5>{t('paymentDetailsSuccess')}</h5>}
              <div>
                <Button caption={t('paymentModalCloseBtn')} onClick={successSubmitHandler} />
              </div>
            </>
          )}
        </div>
      ) : (
        frameData && <iframe src={frameData.url} frameBorder='0' title='payment frame'></iframe>
      )}
    </div>
  )
}

export default PaymentIframe
