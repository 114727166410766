import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import TwoFactorInput, { twoFactorStep } from '../../../../components/Common/TwoFactorInput/TwoFactorInput'
import { COOKIES_MAX_AGE } from '../../../../constants/cookies'
import { urlSignalR, useCrossDomainCookies } from '../../../../environment/urls'
import { getAgencies } from '../../../../http/requests'
import { setItemToLocalStorage } from '../../../../services/localStorageService'
import { saveLoginInfo } from '../../../../services/saveLoginInfo'
import { LOGIN_SET_INFO } from '../../../../types/actionTypes'
import { agencyUserRoleType, buildUserPreferences } from '../../../../types/agencyUserTypes'
import { buildFullPhoneNumber } from '../../../../utils/buildFullPhoneNumber'
import { logout } from '../../actions/loginActions'
import TabsNavbar from '../../../../components/TabsNavbar/TabsNavbar'
import { hebrew } from '../../../../i18n'
import {
  getLoginCodeRequest,
  sendConfirmLogoutRequest,
  sendLoginCodeRequest,
} from '../../../../http/requests/agencyLoginRequest'
import './LoginStep.scss'
import { twoFactorOption, twoFactorOptions } from '../../../../types/twoFactorOptionTypes'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { HubConnectionBuilder } from '@microsoft/signalr'

const CODE_LENGTH = 6
const initialCode = [...Array(CODE_LENGTH)].fill('')

const LoginStep = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  let history = useHistory()

  const [loginData, setLoginData] = useState(null)
  const [multipleLogin, setMultipleLogin] = useState(false)
  const [step, setStep] = useState(twoFactorStep.phoneNumber)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState(initialCode)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [selectedOption, setSelectedOption] = useState(twoFactorOption.byPhone)
  const selectedOptionPhone = selectedOption === twoFactorOption.byPhone
  const selectedOptionKey = selectedOptionPhone ? 'Phone' : 'Email'

  const afterLoginPath = role => (role === agencyUserRoleType.administrator ? 'workstation' : 'lobby')

  const setInitState = () => {
    setLoginData(null)
    setMultipleLogin(false)
    setStep(twoFactorStep.phoneNumber)
    setPhoneNumber('')
    setEmail('')
    setCode(initialCode)
    setLoading(false)
    setError(null)
  }

  const setValueHandler = e => {
    const setValue = selectedOptionPhone ? setPhoneNumber : setEmail
    let { value } = e.target
    if (error) {
      setError(null)
    }
    setValue(_ => value)
  }

  const setCodeHandler = value => {
    if (error) {
      setError(null)
    }
    setCode(_ => value)
  }

  const requestCodeHandler = async () => {
    const fullPhoneNumber = buildFullPhoneNumber(phoneNumber)
    try {
      setCode(initialCode)
      setError(null)
      setLoading(true)
      dispatch(logout())
      const { data } = await getLoginCodeRequest({
        isEmail: !selectedOptionPhone,
        value: selectedOptionPhone ? fullPhoneNumber : email,
      })
      if (data.HasError) {
        setError(data.ErrorMessage)
      } else {
        setStep(twoFactorStep.code)
      }
    } catch (error) {
      setError('Server Error')
    } finally {
      setLoading(false)
    }
  }

  const sendCodeHandler = async () => {
    const fullPhoneNumber = buildFullPhoneNumber(phoneNumber)
    try {
      setError(null)
      setLoading(true)
      const { data } = await sendLoginCodeRequest({
        isEmail: !selectedOptionPhone,
        value: selectedOptionPhone ? fullPhoneNumber : email,
        phone: fullPhoneNumber,
        code: code.join(''),
      })
      setLoginData(data)
      if (data.HasError) {
        setError(data.ErrorMessage)
        setLoading(false)
      } else if (data.Data?.LoggedInFromOtherDevice) {
        setMultipleLogin(true)
      }
    } catch (error) {
      setError('Server Error')
      setLoading(false)
    }
  }

  const submitHandler = async e => {
    e.preventDefault()
    if (step === twoFactorStep.phoneNumber) {
      await requestCodeHandler()
    }
    if (step === twoFactorStep.code) {
      await sendCodeHandler()
    }
  }

  const login = useCallback(async () => {
    const loginInfo = loginData.Data
    const accessToken = loginData?.Data?.AppToken?.AccessToken
    console.log('creating logout connection')
    const connect = new HubConnectionBuilder()
      .withUrl(`${urlSignalR()}logouthub`, {
        accessTokenFactory: () => accessToken,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .withAutomaticReconnect()
      .build()
    connect
      .start()
      .then(() => {
        connect.on('LogoutUser', () => {
          console.log('Got LOGOUT msg')
          dispatch(logout())
          history.push('/login')
          sendConfirmLogoutRequest({
            appId: loginInfo.ApplicationId,
          })
        })
        connect.send('CreateLoginDetails', loginInfo.AppToken.UserId, multipleLogin, loginInfo.ApplicationId)
      })
      .catch(error => {
        console.log(error)
      })
    saveLoginInfo(loginInfo)
    const {
      data: { Data: agenciesData },
    } = await getAgencies(loginInfo.Id)
    const login = {
      AgencyType: loginInfo.AgencyType,
      Id: loginInfo.Id,
      UserName: loginInfo.FullName,
      AppToken: loginInfo.AppToken,
      AccessToken: loginInfo.AppToken.AccessToken,
      RefreshToken: loginInfo.AppToken.RefreshToken,
      RefreshTokenExpireAt: loginInfo.AppToken.RefreshTokenExpireAt,
      UserId: loginInfo.AppToken.UserId,
      id: loginInfo.AppToken.id,
      role: loginInfo.Role,
      preferences: buildUserPreferences(loginInfo.Preferences),
      agencyId: agenciesData.Id,
      agencyName: agenciesData.Name,
      agencyDnsName: agenciesData.dnsName,
      currentAgency: agenciesData.Name,
      showUiTransitionWarn: true,
      applicationId: loginInfo.ApplicationId,
      agreementConfirmed: loginInfo.AgreementConfirmed,
    }
    document.cookie = `agencyId=${agenciesData.Id}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
    setItemToLocalStorage('agencyName', agenciesData.Name)
    setItemToLocalStorage('applicationId', loginInfo.ApplicationId)
    document.cookie = `agencyDnsName=${agenciesData.dnsName}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
    document.cookie = `applicationId=${loginInfo.ApplicationId}; Max-Age=${COOKIES_MAX_AGE}${useCrossDomainCookies}`
    dispatch({ type: LOGIN_SET_INFO, payload: login })
    if (login.currentAgency !== '') history.push(`/${afterLoginPath(loginInfo.Role)}`)
  }, [dispatch, multipleLogin, loginData, history])

  const submitDisabled = () => {
    if (step === twoFactorStep.phoneNumber) {
      return selectedOptionPhone
        ? phoneNumber.length < 9 || loading
        : !email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || loading
    } else if (step === twoFactorStep.code) {
      return loading || code.some(c => c === '')
    }
  }

  const visibleOn = condition => (condition ? 'visible' : 'hidden')

  useEffect(() => {
    setError(null)
  }, [selectedOption])

  useEffect(() => {
    if (loginData && loginData.Data && !loginData?.Data?.LoggedInFromOtherDevice) {
      login()
    }
  }, [loginData, login])

  return (
    <div
      className='login-step-wrapper'
      style={{
        backgroundImage: `url(assets/_login/bg.png), url(assets/_login/bg-secondary.png), url(assets/_login/image${
          hebrew() ? '' : '_en'
        }.png)`,
        backgroundPosition: `left center, left center, ${hebrew() ? 'left' : 'right'} center`,
        backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
        backgroundSize: 'cover, cover, contain',
      }}
    >
      <div className='login-step'>
        <div className='login-step-content'>
          <h4>{t('login.header')}</h4>
          <div className='login-step-form-container'>
            <form action='' onSubmit={submitHandler}>
              <h4 className='login-step-form-title'>{t('login.formTitle')}</h4>
              <p className='login-step-form-subtitle'>{t('login.formSubtitle')}</p>
              <TabsNavbar
                items={Object.values(twoFactorOptions).map(o => ({ ...o, text: hebrew() ? o.name : o.nameEn }))}
                currentItem={selectedOption}
                setCurrentItem={setSelectedOption}
                disabled={step === twoFactorStep.code}
              />
              <label className='login-step-form-label' htmlFor=''>
                {t(`login.input${selectedOptionKey}${step === twoFactorStep.code ? 'Sms' : ''}Title`)}
              </label>
              <TwoFactorInput
                step={step}
                phoneNumber={selectedOptionPhone ? phoneNumber : email}
                setPhoneNumber={setValueHandler}
                phoneNumberPlaceholder={t(`login.input${selectedOptionKey}Placeholder`)}
                codeLength={CODE_LENGTH}
                code={code}
                setCode={setCodeHandler}
                onSubmit={submitHandler}
                submitBtnDisabled={submitDisabled()}
                error={error}
                inputDisabled={loading}
                getCodeBtnCaption={t('login.getCodeBtn')}
                sendCodeBtnCaption={t('login.sendCodeBtn')}
                inputType={twoFactorOptions[selectedOption].inputType}
              />
              <p className='login-step-form-error' style={{ visibility: visibleOn(error) }}>
                {error ?? 'error'}
              </p>
              <p className='login-step-form-remark' style={{ visibility: visibleOn(step === twoFactorStep.code) }}>
                {t('login.didntGetCode')} <span onClick={requestCodeHandler}>{t('login.sendCodeAgain')}</span>
              </p>
            </form>
          </div>
          <p className='login-step-register'>
            {t('login.noAccount')} <NavLink to={'register'}>{t('login.register')}</NavLink>
          </p>
        </div>
      </div>
      {multipleLogin && (
        <ConfirmDialog
          title={t('alreadyLoggedDialog.title')}
          text={t('alreadyLoggedDialog.text')}
          confirmText={t('alreadyLoggedDialog.confirmBtn')}
          declineText={t('alreadyLoggedDialog.declineBtn')}
          confirm={() => login(loginData)}
          decline={() => setInitState()}
        />
      )}
    </div>
  )
}
export default LoginStep
