import { useDispatch, useSelector } from 'react-redux'
import { hebrew } from '../../../../i18n'
import { SHARED_SET_COMMISSION_VIEW } from '../../../../types/actionTypesShared'
import { isDeltaAgency } from '../../../login/reducers/loginSelectors'
import { deltaCommissionView } from '../../../../types/deltaCommissionViewTypes'
import { updateLocalStorageObject } from '../../../../services/localStorageService'
import './CommissionViewSelector.scss'

const CommissionViewSelector = () => {
  const { commissionView } = useSelector(({ shared }) => shared)
  const { UserId } = useSelector(({ login }) => login)

  const dispatch = useDispatch()

  if (!isDeltaAgency()) {
    return null
  }

  const selectViewHandler = type => {
    if (commissionView !== type) {
      updateLocalStorageObject(UserId, 'commissionViewType', type)
      dispatch({ type: SHARED_SET_COMMISSION_VIEW, payload: type })
    }
  }

  return (
    <div className='commission-view-selector-wrapper'>
      <div className='commission-view-selector'>
        {deltaCommissionView[commissionView][`abbr${hebrew() ? '' : 'En'}`]}
        <ul>
          {Object.values(deltaCommissionView).map(v => (
            <li key={v.type} onClick={() => selectViewHandler(v.type)}>
              {v[`name${hebrew() ? '' : 'En'}`]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CommissionViewSelector
