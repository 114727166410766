import { useState } from 'react'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Loader from '../../../../components/Common/Loader/Loader'
import MobileHeaderBase from '../../../../components/Common/MobileHeaderBase/MobileHeaderBase'
import { hebrew } from '../../../../i18n'
import {
  customerTask,
  customerTaskStatus,
  customerTaskStatusType,
  customerTaskType,
} from '../../../../types/customerTaskTypes'
import { irregularReasonObject } from '../../../../types/irregularReasonTypes'
import { formatAsCurrency, formatAsShortDate } from '../../../../utils/formatAs'
import './TasksDetailsMobile.scss'
import SetTaskStatusMenu from '../SetTaskStatusMenu/SetTaskStatusMenu'

const TasksDetailsMobile = ({ task, closeDetails = () => {} }) => {
  const { t } = useTranslation('workstation')
  const { taskStatusLoading } = useSelector(state => state.workstation)
  const [showSetStatusMenu, setShowSetStatusMenu] = useState(false)

  const {
    categoryEnglishName,
    categoryName,
    date,
    customerName,
    description,
    irregularReason,
    monetaryValue,
    policyIdNumber,
    responsibleEmployeeName,
    status,
    type,
  } = task

  const headerText = customerName ?? 'Custom Task'
  const taskType = hebrew()
    ? customerTask[type].text() +
      (type === customerTaskType.irregularity ? ' - ' + irregularReasonObject[irregularReason]?.name : '')
    : customerTask[type].textEn() +
      (type === customerTaskType.irregularity ? ' - ' + irregularReasonObject[irregularReason]?.nameEn : '')

  const category = hebrew() ? categoryName : categoryEnglishName

  return createPortal(
    <div className='task-details-mobile-container'>
      <MobileHeaderBase text={headerText} goBack={closeDetails} />
      <div className='task-details-mobile'>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.taskStatus')}</div>
          <div className='task-details-mobile-status'>
            {taskStatusLoading[task.id] && (
              <div className='task-details-mobile-status-loader-overlay'>
                <Loader small />
              </div>
            )}
            <button
              className={classNames('task-details-mobile-status-chip', {
                treated: status === customerTaskStatusType.treated,
                opened: showSetStatusMenu,
              })}
              onClick={e => {
                e.stopPropagation()
                !showSetStatusMenu && setShowSetStatusMenu(true)
              }}
            >
              <span>{hebrew() ? customerTaskStatus[status].text() : customerTaskStatus[status].textEn()}</span>
              <div className='task-details-mobile-status-chip-arrow-ico'>
                <img src='./assets/dropdown-icon-light-sm/dropdown-icon-light-sm.png' alt='opn' />
              </div>
            </button>
            {showSetStatusMenu && (
              <div className='task-details-mobile-status-menu-wrapper'>
                <SetTaskStatusMenu closeMenu={() => setShowSetStatusMenu(false)} tasks={[task]} />
              </div>
            )}
          </div>
        </div>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.policyNumberSh')}</div>
          <div>{policyIdNumber ?? '-'}</div>
        </div>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.taskTypeSh')}</div>
          <div>{taskType}</div>
        </div>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.taskDescSh')}</div>
          <div>{description ?? ' - '}</div>
        </div>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.monetaryValue')}</div>
          <div>{formatAsCurrency(monetaryValue)}</div>
        </div>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.category')}</div>
          <div>{category ?? ' - '}</div>
        </div>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.responsibleEmployee')}</div>
          <div>{responsibleEmployeeName}</div>
        </div>
        <div className='task-details-mobile-row'>
          <div>{t('listHeader.date')}</div>
          <div>{formatAsShortDate(date)}</div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default TasksDetailsMobile
