import { HubConnectionBuilder } from '@microsoft/signalr'
import { useEffect, useState } from 'react'
import { getItemFromLocalStorage } from '../../services/localStorageService'
import { urlSignalR } from '../../environment/urls'
import { logout } from '../../features/login/actions/loginActions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { sendConfirmLogoutRequest } from '../../http/requests/agencyLoginRequest'

const SignalRLogout = () => {
  const { UserId } = useSelector(state => state.login)
  const dispatch = useDispatch()
  let history = useHistory()

  const [connection, setConnection] = useState(null)

  const accessToken = getItemFromLocalStorage('AccessToken')
  const appId = getItemFromLocalStorage('applicationId')

  useEffect(() => {
    if (accessToken && !connection) {
      console.log('connecting')
      const connect = new HubConnectionBuilder()
        .withUrl(`${urlSignalR()}logouthub`, {
          accessTokenFactory: () => accessToken,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .withAutomaticReconnect()
        .build()
      setConnection(connect)
    } else if (!accessToken && connection) {
      connection.stop().then(() => setConnection(null))
    } else if (connection && connection.state !== 'Connected') {
      connection
        .start()
        .then(() => {
          connection.on('LogoutUser', () => {
            console.log('Got LOGOUT msg')
            dispatch(logout())
            sendConfirmLogoutRequest({
              appId: appId,
            })
            history.push('/login')
          })
          connection.send('CreateLoginDetails', UserId)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [connection, accessToken, appId, dispatch, history])

  return null
}

export default SignalRLogout
