import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { agencyRoutes } from '../../../../environment/urls'
import { hebrew } from '../../../../i18n'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import CountBadge from '../../../../components/CountBadge/CountBadge'
import { Home } from '@mui/icons-material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import './ScanSidebar.scss'
import { removeCompanyRequest } from '../../../../http/requests/scanRequests'
import { scanSelectedMonth } from '../../reducers/scanSelectors'
import { getReportRecordsMonthStatus } from '../../actions/scanActions'
import Loader from '../../../../components/Common/Loader/Loader'

const ScanSidebar = () => {
  const { t } = useTranslation('scan')
  const baseRoute = agencyRoutes.scan
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const scanInProgress = false

  const companyMenuItems = company => [
    {
      text: t('sidebar.editLogin'),
      clickHandler: () => {
        history.push(`/${baseRoute}/${company.id}?editLogin=true`)
      },
    },
    {
      text: t('sidebar.downloadSupport'),
      clickHandler: () => {
        const { id } = company
        const link = document.createElement('a')
        link.download = `${id}_support.rar`
        link.href = `./assets/_files/support/${id}.rar`
        link.click()
        link.remove()
      },
    },
    // ...(company.Files.length > 0
    //   ? [
    //       {
    //         text: t('downloadFiles'),
    //         clickHandler: () => {
    //           setCompany(company)
    //           setShowReportsListModal(true)
    //         },
    //       },
    //     ]
    //   : []),
    {
      text: t('sidebar.deleteCompany'),
      clickHandler: async () => {
        try {
          const {
            data: { hasError },
          } = await removeCompanyRequest(company.id)
          if (!hasError) {
            if (pathname === `/${baseRoute}/${company.id}`) {
              history.push('/' + baseRoute)
            }
            dispatch(getReportRecordsMonthStatus({ month: scanSelectedMonth() }))
          }
        } catch (error) {}
      },
      warn: true,
    },
  ]
  const {
    monthStatus: { companies },
  } = useSelector(({ scan }) => scan)

  return (
    <aside className='scan-sidebar'>
      <header>
        <p>{t('sidebar.header')}</p>
        {/* <button>
          <Settings />
        </button> */}
      </header>
      <div className={classNames('scan-sidebar-item', { 'current-scan-link': pathname === '/' + baseRoute })}>
        <div className='scan-sidebar-item-link'>
          <NavLink to={'/' + baseRoute} exact>
            <Home />
            <p>{t('sidebar.summary')}</p>
          </NavLink>
        </div>
      </div>
      <div className='scan-sidebar-items'>
        <ul>
          {companies.map(c => (
            <li
              key={c.id}
              className={classNames('scan-sidebar-item', {
                'current-scan-link': pathname === `/${baseRoute}/${c.id}`,
              })}
            >
              <div className='scan-sidebar-item-link'>
                <NavLink to={`/${baseRoute}/${c.id}`}>
                  <img src={`./assets/companies-logos-light/${c.id}.png`} alt={c.name} />
                  <p>{hebrew() ? c.name : c.nameEn}</p>
                  {/* <p className='scan-sidebar-item-status'>Scan Status</p> */}
                </NavLink>
              </div>
              <div className='scan-sidebar-item-extras'>
                {scanInProgress ? (
                  <div className='scan-sidebar-item-extras-loader'>
                    <Loader />
                  </div>
                ) : (
                  <CountBadge count={c.availableReportsCount} />
                )}
                <MeatBallsMenu menuItems={companyMenuItems(c)} positionFixed menuSize='xs' />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

export default ScanSidebar
