import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Loader from '../Common/Loader/Loader'
import ButtonRounded from '../Common/ButtonRounded/ButtonRounded'
import './BigAgencyPaymentFrame.scss'
import { applySaveBigAgencyCardRequest, saveBigAgencyCardInfoRequest } from '../../http/requests/agencyHouseRequests'

const BigAgencyPaymentFrame = ({ onPaymentSuccess = () => {} }) => {
  const [frameData, setFrameData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [updateError, setUpdateError] = useState(null)
  const [updateSuccess, setUpdateSuccess] = useState(null)

  const { t } = useTranslation('common')

  const buildFrame = async () => {
    try {
      setError(null)
      setSuccess(null)
      setFrameData(null)
      setLoading(true)
      const {
        data: { Data, HasError, ErrorMessage },
      } = await saveBigAgencyCardInfoRequest()
      if (HasError) throw new Error(ErrorMessage)
      setFrameData({ processId: Data.ProcessId, processToken: Data.ProcessToken, url: Data.Url })
      setLoading(false)
    } catch (error) {
      console.log(error)
      setError('Server is unavailable')
      setLoading(false)
    }
  }

  const paymentEventHandler = useCallback(
    async result => {
      const {
        origin,
        data: { action: frameAction, status },
      } = result
      const { origin: frameOrigin } = new URL(frameData.url)
      if (origin.includes('meshulam') || origin === frameOrigin) {
        let errorMessage = null
        let successMessage = null
        switch (frameAction) {
          case 'close': {
            console.log('closing frame')
            errorMessage = 'Payment was cancelled'
            break
          }
          case 'payment': {
            if (status == 1) {
              console.log('payment success')
              successMessage = 'payment success'
            }
            if (status == 0) {
              console.log('payment error')
              errorMessage = 'Payment Error'
            }
            break
          }
          case 'failed_to_load_page': {
            console.log('failed_to_load_page')
            errorMessage = 'Failed to connect to payment system'
            break
          }
          default:
            console.log('default')
            errorMessage = 'Unknown Frame Action'
        }
        if (successMessage) {
          setSuccess(successMessage)
          try {
            setUpdateError(null)
            setUpdateSuccess(null)
            const {
              data: { HasError, ErrorMessage },
            } = await applySaveBigAgencyCardRequest(frameData.processId, frameData.processToken)
            if (HasError) {
              const err = JSON.parse(ErrorMessage)
              setUpdateError(err.message)
            } else {
              setUpdateSuccess('Updated Successfully')
            }
          } catch (error) {
            console.log(error)
            setUpdateError(error)
          }
        } else {
          setError(errorMessage)
          setFrameData(null)
        }
      }
    },
    [frameData]
  )

  useEffect(() => {
    buildFrame()
  }, [])

  useEffect(() => {
    if (frameData) {
      window.addEventListener('message', paymentEventHandler)
      return () => {
        window.removeEventListener('message', paymentEventHandler)
      }
    }
  }, [frameData, paymentEventHandler])

  if (loading) {
    return (
      <div className='big-agency-payment-frame-container'>
        <Loader />
      </div>
    )
  }

  return (
    <div className='big-agency-payment-frame-container'>
      {/* <div style={{ position: 'absolute', color: 'red' }}>{error || updateError || success || updateSuccess}</div> */}
      {error || updateError || success || updateSuccess ? (
        <div className={classNames('big-agency-payment-frame-placeholder', { error: error || updateError })}>
          <h4>{t(`payment${error || updateError ? 'Failed' : 'Success'}`)}</h4>
          <h5>{t(`paymentDetails${error || updateError ? 'Failed' : 'Success'}`)}</h5>
          <ButtonRounded
            onClick={
              error
                ? () => buildFrame()
                : () => {
                    onPaymentSuccess()
                  }
            }
          >
            {t(`paymentModal${error ? 'Try' : 'Close'}Btn`)}
          </ButtonRounded>
        </div>
      ) : (
        frameData && <iframe src={frameData.url} frameBorder='0' title='payment frame'></iframe>
      )}
    </div>
  )
}

export default BigAgencyPaymentFrame
