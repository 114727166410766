import classNames from 'classnames'
import LoadingIconSvg from '../SvgIcons/LoadingIconSvg/LoadingIconSvg'
import './InputText.css'

const InputText = ({
  inputType = 'text',
  inputName,
  id,
  label,
  value,
  onChange = () => {},
  required = false,
  placeholder = ' ',
  showCharsCount = false,
  charsCount,
  minLength,
  maxLength,
  validationMessage,
  iconPath,
  disabled = false,
  inputRef,
  autoFocus = false,
  loading = false,
  error,
  style = {},
}) => {
  const changeHanlder = e => {
    if (inputType === 'number' && e.target.value.length > 0) {
      const digits = new RegExp('^[0-9]+$')
      const res = digits.test(e.target.value)
      if (!res) {
        return
      }
    }
    onChange(e.target.value)
  }

  return (
    <div className='input-text-wrapper'>
      <div className={classNames('input-text-container', { disabled })}>
        <input
          type={inputType === 'number' ? 'text' : inputType}
          name={inputName}
          id={id}
          placeholder={placeholder}
          autoComplete='off'
          required={required}
          value={value}
          onChange={changeHanlder}
          minLength={minLength && minLength}
          maxLength={maxLength && maxLength}
          disabled={disabled}
          ref={inputRef}
          autoFocus={autoFocus}
          style={{ ...style }}
        />
        {label && (
          <label htmlFor={id}>
            {label}
            {required && '*'}
          </label>
        )}
        <span className='line'></span>
        {loading && (
          <div className='input-icon'>
            <LoadingIconSvg />
          </div>
        )}
        {iconPath && (
          <div className='input-icon'>
            <img src='./assets/phone/Call.png' alt='phone' className='img-icon' />
          </div>
        )}
      </div>
      {error && <div className='input-text-error-container'>{error}</div>}
      <div className='input-text-hints-container'>
        <div className='input-text-hints-validation-msg'>{validationMessage && validationMessage}</div>
        <div className='input-text-hints-chars-count'>{showCharsCount && `${value.length}/${charsCount}`}</div>
      </div>
    </div>
  )
}

export default InputText
