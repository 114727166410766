import { useTranslation } from 'react-i18next'
import NoData from '../../../../components/Common/NoData/NoData'
import PolicyListItemCard from '../PolicyListItemCard/PolicyListItemCard'
import { getYearAndMonth } from '../../../../services/getMonthName'
import EntityStatusChip from '../../../../components/Common/EntityStatusChip/EntityStatusChip'
import { entityStatusType } from '../../../../types/entityStatusType'
import Loader from '../../../../components/Common/Loader/Loader'
import './PolicyTypesList.scss'

const PolicyTypesList = ({ policyTypes, loading }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('policies')

  if (loading) {
    return <Loader />
  }

  if (policyTypes.length === 0) {
    return <NoData text={t('policyCard.noCoveragesFound')} />
  }

  return (
    <div className='policy-card-types-list'>
      <div className='policy-types-grid policy-sidecard-list-heading'>
        <div>{t('list.heading.policyTypeName')}</div>
        <div>{t('list.heading.startDate')}</div>
        <div>{t('list.heading.endDate')}</div>
        <div>{t('list.heading.status')}</div>
      </div>
      {policyTypes.map(pt => (
        <PolicyListItemCard classes={'policy-type-item policy-types-grid'} key={pt.policyTypeId}>
          <div>{pt.name ?? '--'}</div>
          <div className='date-cell'>{getYearAndMonth(pt.startDate, { language })}</div>
          <div className='date-cell'>{getYearAndMonth(pt.endDate, { language })}</div>
          <div>
            <EntityStatusChip status={pt.isActive ? entityStatusType.active : entityStatusType.inactive} />
          </div>
        </PolicyListItemCard>
      ))}
    </div>
  )
}

export default PolicyTypesList
