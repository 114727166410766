import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCustomersTotalDashboard } from '../../actions/customersActions'
import { customersListType } from '../../../../types/tablesTypes'
import { useTranslation } from 'react-i18next'
import { policyRating } from '../../../../types/policyRatingTypes'
import { policyRatingType } from '../../../../types/policyRatingTypes'
import { hebrew } from '../../../../i18n'
import { cn } from '../../../../utils/stylesUtils'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import './CustomersDashboard.scss'
import Loader from '../../../../components/Common/Loader/Loader'

const CustomersDashboard = ({ currentListType, setCurrentListType }) => {
  const { t } = useTranslation('customers')
  const dispatch = useDispatch()
  const {
    customersTotalCount,
    customersGreenCount: green,
    customersOrangeCount: orange,
    customersRedCount: red,
    totalGroups,
    dashboardLoading,
  } = useSelector(state => state.customers)
  const { familyGroupsType } = useSelector(({ shared }) => shared)

  const customersRating = {
    green,
    orange,
    red,
  }

  const setRatingListType = listType =>
    setCurrentListType(currentListType === listType ? customersListType.rating : listType)

  let header = null
  let content = null
  switch (currentListType) {
    case customersListType.rating:
    case customersListType.ratingGreen:
    case customersListType.ratingOrange:
    case customersListType.ratingRed:
    default: {
      header = (
        <>
          {t('totalCustomers')} <span>({Intl.NumberFormat().format(customersTotalCount)})</span>
        </>
      )
      const ratingItem = rating => policyRating.find(r => r.key === rating)

      content = (
        <div className='customers-rating-cards'>
          {Object.entries(policyRatingType).map(([k, val]) => (
            <div
              key={val}
              className={cn(ratingItem(val).color, { current: ratingItem(val).listTypeCustomers === currentListType })}
              onClick={() => setRatingListType(ratingItem(val).listTypeCustomers)}
            >
              <p>{ratingItem(val)[`name${hebrew() ? '' : 'En'}`]}</p>
              {dashboardLoading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <p>{Intl.NumberFormat().format(customersRating[k])}</p>
              )}
            </div>
          ))}
        </div>
      )
      break
    }
    case customersListType.distribution:
      header = t('customersDistribution')
      break
    case customersListType.groups:
      header = (
        <>
          {t('totalGroups')} <span>({Intl.NumberFormat().format(totalGroups)})</span>
        </>
      )
      break
  }
  useEffect(() => {
    dispatch(getCustomersTotalDashboard({ calculateByGroup: familyGroupMode() }))
  }, [dispatch, familyGroupsType])

  return (
    <div className='customers-dashboard'>
      <p>{header}</p>
      {content}
    </div>
  )
}

export default CustomersDashboard
